import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './styles/style.css';
import './styles/fonts.scss';
import './styles/animations.scss';

import './i18n';

import Home from './routes/home';
import Inventory from './routes/inventory/inventory';
import Card from './routes/card/card';
import FeddingEvents from './routes/feedingEvents/feedingEvents';
import FeedingEventCard from './routes/feedingEvents/feedingEventCard';
import { TranslationsContextProvider } from './stores/translationsContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/inventory",
    element: <Inventory />,
  },
  {
    exact:true,
    path: "/:chapterId/:cardId",
    element: <Card />,
  },
  {
    exact:true,
    path: "/feeding-event",
    element: <FeddingEvents />,
  },
  {
    exact:true,
    path: "/feeding-event/:cardId",
    element: <FeedingEventCard />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TranslationsContextProvider>
        <RouterProvider router={router} />
    </TranslationsContextProvider>
  </React.StrictMode>
);
