import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';

const InterludeBlackBoxContainer = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId,
    removeLink
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let groupContainer = template.render[0];
    let blackboxContainer = groupContainer.children[0];
    var bodyText = blackboxContainer.children[0];
    var clanIcon = blackboxContainer.children[2];
    let dottedContainer = groupContainer.children[1];
    const dottedModuleContainer = dottedContainer.children[0];

    const masterContainerStyle = {
        ...getContainerStyle(groupContainer.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    const blackboxContainerStyle = {
        ...getContainerStyle(blackboxContainer.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    const dottedContainerStyle = {
        ...getContainerStyle(dottedContainer.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='flex justify-between items-center' style={{ ...blackboxContainerStyle }}>
                <VariableValueExtractor variableId={bodyText.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink={!removeLink}
                    ctaId={ctaId}
                    
                    parentId={parentId} />

                <div className='force-icon-font-size'>
                    <VariableValueExtractor variableId={clanIcon.variable}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={clanIcon.uuid}
                        isLink={false}
                        ctaId={ctaId}
                        parentId={parentId}
                        fontSize={"50px !important"}
                    />
                </div>
            </div>

            <div style={dottedContainerStyle}>
                {findModuleVariables(dottedModuleContainer.variable, variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            fullWidth={true}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default InterludeBlackBoxContainer;