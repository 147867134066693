import './_templates.scss'
import { findModuleVariables, findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const FinalDeductionFirstPage = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48", template, cards, currentCard, currentCard.variablesValues, templateData).value;

    return (
        <div className="investigation-first-page w-full h-full flex-col bg-center bg-cover flex  items-center pt-10"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="flex flex-col items-center w-full px-4">
                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-1" />
                <VariableValueExtractor variableId={"ccf95f02-9d64-4e53-8e30-45229f27eaa6"}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    variables={currentCard.variablesValues}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-2" />
            </div>

            <div className='background-image flex items-center justify-center' style={{
                backgroundImage: "none", // `url(${BLOB_URL}upload/images/64ed011e17b7622c4d323360/Booklets/icons/HEX_Investigation_Gold.webp)`
                height: '90px',
                width: '90px'
            }}>
                <div style={{ marginTop: '-15px' }}>
                    <VariableValueExtractor variableId={"157a9917-5717-4c5e-ab61-a9440fe892c4"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div className='flex flex-col text-justify' style={{ width: '80%' }}>
                {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                            separatorColor="#f1e4d7"
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default FinalDeductionFirstPage;