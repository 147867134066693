import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfAllActionTokensHaveBeenRemovedFromTheTileChapterFail = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
   
    let containerRender = template.render[0];
    let textRender1 = containerRender?.children[0]?.children[0]?.uuid;
    let textRender2 = containerRender?.children[0]?.children[1]?.variable;
    let textRender3 = containerRender?.children[1]?.children[0]?.children[0]?.uuid;
    let textRender4 = containerRender?.children[1]?.children[0]?.children[1]?.uuid;
 
    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
    
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div style={{color:"#f1e4d7", fontSize: "15.5px", fontFamily: "CrimsonPro-Regular"}}>
                <VariableValueExtractor variableId={textRender1}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div >
                    <VariableValueExtractor variableId={textRender2}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>
            
            <div className='flex justify-between items-center'>
                <div>
                    <VariableValueExtractor variableId={textRender3}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>

                    <VariableValueExtractor variableId={textRender4}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div>
                    <ChaptersIcons name={findNestedVariable("65b3e6e7-3798-4ca7-a161-626206188e3d", variables)?.value}
                    color={getRenderColor("1ac15f9f-2300-4e8f-b7f7-1c6f07fb8506", template.render, templateData)}
                    width='80px'
                    height='80px'/>
                </div>
            </div>
        </div>
    )
}

export default IfAllActionTokensHaveBeenRemovedFromTheTileChapterFail;