import './_templates.scss'
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import { ReactComponent as HandIcon } from '../icons/DefaultIconSvgs/Swipe.svg';
import { useTranslation } from 'react-i18next';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const CoverPrologueFront = ({
    currentCard,
    template,
    card,
    cards,
    templateData,
    ctaId,
}) => {
    const {t, i18n} = useTranslation();

    const parentId = currentCard._id;

    let backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48", template, cards, currentCard, currentCard.variablesValues, templateData).value;

    return (
        <div className="cover-prologue-front w-full h-full flex-col bg-center bg-cover flex justify-center items-center pb-10"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className='grow flex flex-col justify-center' style={{marginTop:'150px'}}>
                <div className="flex flex-col items-center w-full px-4 text-center">
                    <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />
                    <VariableValueExtractor variableId={"ba7d62ec-2699-43c9-b6e3-ee4c84deb498"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />
                </div>
                <div style={{ height: "70px" }}  className='text-center'>
                    <VariableValueExtractor variableId={"a7ecd112-acc5-42f4-8c19-cdd82000b53f"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId}/>
                
                </div>
            </div>
            <div className='mt-10 flex flex-col items-center text-center w-3/4'>
                <HandIcon width={50} height={50}/>
                <p className='mt-2' style={{fontFamily:"CrimsonPro", color:"#949494", fontWeight:'bold', fontStyle:"italic"}}>
                    {t("swipe-left-or-right")}
                </p>
            </div>
        </div>
    )
}

export default CoverPrologueFront;