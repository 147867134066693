import { useNavigate } from "react-router-dom";
import {DEFAULT_ORANGE_CODE} from '../../utils/constants';
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TableOfContentSectionPage = ({
    variables,
    template,
    cards,
    card,
    deckId,
    templateData,
    ctaId,
    parentId
}) =>{

    const navigate = useNavigate();

    var variable = findVariableOrDefaultToTempateValue("e6c2a780-7f54-4176-9ea0-c6e56ae01d65", template, cards, card, variables, templateData);
    const handlePageClick = () => {
        navigate(`/${deckId}/${variable.value}`);
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="flex w-full items-end cursor-pointer mb-1" style={{color:DEFAULT_ORANGE_CODE, fontSize:"20px"}} onClick={() => {handlePageClick()}}>
            <div className='break-keep'>
                <VariableValueExtractor variableId={"73a1b176-a04b-4188-8a2f-31be7bcfed34"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className="w-full items-end mb-1.5" style={{height:"1px", backgroundColor:DEFAULT_ORANGE_CODE}}></div>
            <div className='break-keep'>
                <VariableValueExtractor variableId={"ed9e22d3-c353-4462-b1e7-5a43fdb98258"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default TableOfContentSectionPage;