import { findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
const SetupNpcColumn = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"


    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="flex flex-wrap justify-center">
            {findModuleVariables("7cf9b197-6169-4a46-9296-41af24a586b0", variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        noStyle={true}
                    />
                )
            })}
        </div>
    )
}

export default SetupNpcColumn;