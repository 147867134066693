import HexGrid from '../tools/HexGrid';
const SetupImageHexGrid = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData
}) =>{

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"
    
    if(variables.variablesValues){
        variables = variables.variablesValues;
    }

    const containerStyle = {
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={containerStyle}>
            <HexGrid 
                variables={variables}
                template={template}
                cards={cards}
                card={card}
                templateData={templateData}
                variable="54b9a0e5-42cd-458c-bb4a-1894558e54ed"

            />
        </div>
    )
}

export default SetupImageHexGrid;