import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import ClanIcons from '../icons/ClanIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouAreCharacterAndYouHaveItemCardSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender.children[0].children[0]?.uuid;
    const ctaVariableId = template.render[0].children[1].children[0]?.variable;

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='grow'>
                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div>
                    {findNestedModuleVariables(ctaVariableId, variables)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                separatorColor={'black'}
                            />
                        )
                    })}
                </div>
                
            </div>
            <div className='flex justify-start '>
                <ClanIcons name={findNestedVariable("2a68efac-315a-4660-a396-22dd654716f3", variables)?.value}
                    color={getRenderColor("6397ad5b-51f9-4a4b-af9c-bba62cc9f6cb", template.render, templateData)} />
            </div>
        </div>
    )
}

export default IfYouAreCharacterAndYouHaveItemCardSandbox;