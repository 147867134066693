import { generateText, getStyle, getColor } from './frogs-text-generator';

export const getBorderStyle = (display, templateData) => {
    if(!display) return null;

    return {
        borderWidth:display?.borderWidth ? display.borderWidth * 3: null,
        borderColor:display?.borderColor ? getColor(display.borderColor, templateData) : null,
        borderRadius: display?.borderRadius,
        borderStyle:display?.borderStyle
    }
};

export const getRenderColor = (id, render, templateData) => {
    var x = findTextRenderObject(id, render);
    if(!x) return null;
    var style = getStyle(x.style, templateData);
    return style?.color;
};

export const findNestedVariable = (id, variables) => {
    if(!variables) return null;
    if(variables?.[id]) return variables?.[id];

    if(variables.length){
        for(let variable of variables){
            var value = findNestedVariable(id, variable.variablesValues);
            if(value) return value;
        }
    }

    return null;
}

export const findModuleVariables = (id, cardOrValues) => {

    if(cardOrValues[id]){
        return cardOrValues[id].values;
    }

    if(cardOrValues?.variablesValues?.[id] && cardOrValues.variablesValues[id].values){
        return cardOrValues.variablesValues[id].values;
    }

    return [];
}

export const findNestedModuleVariables = (id, variablesValues) => {
    if(!id || !variablesValues) return;

    if(variablesValues[id] && variablesValues[id].values){
        return variablesValues[id].values;
    }else{
        let keys = Object.keys(variablesValues);
        for(let key of keys){
            if(variablesValues[key] && variablesValues[key].values){
                for(let value of variablesValues[key].values){
                    if(value.uuid === id) {
                        return value;
                    }
                }
            }
        }
    }

}

export const findTextRenderObject = (uuid, parent) => {
    if (parent) {
        for (let item of parent) {
            if (item.uuid === uuid) {
                return item;
            }

            if (item.children) {
                const result = findTextRenderObject(uuid, item.children);
                if (result !== null) {
                    return result;
                }
            }
        }
    }

    return null;
};

export const findRenderObjectByNameAndType = (name, type, parent) => {
    if (parent) {
        for (let item of parent) {
            if (item.name === name && item.type === type) {
                return item;
            }

            if (item.children) {
                const result = findRenderObjectByNameAndType(name, type, item.children);
                if (result !== null) {
                    return result;
                }
            }
        }
    }

    return null;
};

export const findVariableOrDefaultToTempateValue = (id, template, cards, card, variables, templateData, renderId, isLink, noStyle, translationsCard, translationsCards, language) => {
    if(!card) return null;

    // if(language === "fr"){
    //     let valueFr = translationsCard.variablesValues.
    // }

    let value = findNestedVariable(id, variables);

    
    if(!value){
        value = findTextRenderObject(id, template.render);
    }
    

    if(!cards) return value;

    if(renderId && value){
        value.style = findTextRenderObject(renderId, template.render)?.style; 
    }

    if(value && value.type === "string"){
        return (
            <p style={!noStyle ? getStyle(value.style, templateData) : null}>
                {value.value}
            </p>
        )
    }
    

    if(value && value.text){
        value = generateText(value.text, value.style, cards, card._id, variables, templateData, isLink, noStyle);
        
    }

    if(value && value.value && value.type === 'text'){
        value = generateText(value.value, value.style, cards, card._id, variables, templateData, isLink, noStyle);
        
    }

    return value;
}

export const getContainerStyle = (id, template, cards, card, variables, templateData, renderId) => {
    var value = findVariableOrDefaultToTempateValue(id, template, cards, card,variables, templateData, renderId);

    if(value && value.display){
        var containerDisplay = value.display;
        return {
            ...getBorderStyle(containerDisplay, templateData),
            backgroundColor:containerDisplay?.fill && containerDisplay.fill !== 'none' ? getColor(containerDisplay.fill, templateData) : null
        }
    }

    return value;
}