import './_inventory.scss';
import inventories from '../../_data/inventories.json';
import inventoriesFr from '../../_data/inventories-fr.json';
import { Link } from 'react-router-dom';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';


export default function Inventory() {
    const [inventory, setInventory] = useState(null);
    const [chaptersList, setChaptersList] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setInventoryContent();
    }, [])

    useEffect(() => {
        setInventoryContent();
    }, [i18n])

    const setInventoryContent = () => {
        let inv = null;
        // if (i18n.language === 'en') {
            inv = inventories.find(_ => _.id === "alex");
        // } else {
        //     inv = inventoriesFr.find(_ => _.id === "alex");
        // }

        setInventory(inv);

        let data = [];

        inv.chapters.forEach(_ => {
            data.push({
                name: _.name,
                item: null,
                title: true
            });

            _.items.forEach((i, index) => {
                data.push({
                    name: i.name,
                    title: false,
                    item: i.item,
                    line: index < _.items.length - 1
                })
            })
        });

        setChaptersList(data);
    }



    const topLineImgSrc = BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/Storybook/Graphic_Assets/Ank_Line_Gold.webp';
    const bottomLineImgSrc = BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/Storybook/Graphic_Assets/Tip_Line_Gold.webp'

    return  inventory && (<div
        className="inventory2-page w-full h-full overflow-y-auto relative flex-col bg-cover flex justify-center items-center bg-local"
        style={{ backgroundImage: `url(/images/inventory/BG.png)` }}
    >
        <div className='background-overlay-faded'>
            <div className='w-full items-center flex-col overflow-x-hidden'>
                <div className='top-space'></div>

                <div className=''>
                    <div className='line top'>
                        <img src={topLineImgSrc + "?v=" + V_NUMBER} alt="img" />
                    </div>
                    <h1>{inventory.name}</h1>
                    <div className='line bottom'>
                        <img src={bottomLineImgSrc + "?v=" + V_NUMBER} alt="img" />
                    </div>
                    <p className='description'>{inventory.description}</p>
                </div>
                <div className='inventory-list-container'>
                    {chaptersList.map((chapter, index) => {
                        return (
                            <div key={index} className={chapter.title ? 'item chapter-title' : ''}>
                                <div className={!chapter.title ? 'item chapter-item' : 'item'}>
                                    <p>
                                        {chapter.name}
                                    </p>
                                    {!chapter.title && (<p className='text-orange'>{chapter.item}</p>)}
                                </div>
                                {chapter.line && (<div className='white-gradient-line'></div>)}
                            </div>
                        )
                    })}

                </div>

                <div className='button-container'>
                    <Link to={'/'} className='underline'>
                        {t("home")}
                    </Link>
                </div>
            </div>
        </div>
    </div>)
}