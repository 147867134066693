import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";
const TextBookletGeneralV2 = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    

    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    let textRenderId = template.render[0].children[0].uuid;
    let variableId = template.render[0].children[0].variable;

    if(!variableId) variableId = textRenderId;

    return (
        <div name={`${template._id} - ${template.name}`} className="my-1" style={{width: !fullWidth ? CONTENT_WIDTH : null}}>
            <VariableValueExtractor variableId={variableId}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={textRenderId}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default TextBookletGeneralV2;