import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const MakeARouseCheck = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    let containerRender = template.render[0];
    let textRender = containerRender?.children[0]?.uuid;
    let succesTextRender = containerRender?.children[1]?.children[1]?.uuid
    let faillureTextRender = containerRender?.children[2]?.children[1]?.uuid
    let skullureTextRender = containerRender?.children[3]?.children[1]?.uuid


    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="flex items-center">
                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className="flex flex-col " >

                <div className="flex items-start my-1">
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="P" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={succesTextRender}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

                <div className="flex items-start my-1">
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={faillureTextRender}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

                <div className="flex items-start my-1">
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="L" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={skullureTextRender}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MakeARouseCheck;