import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle} from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TextBoxContainerContainerIconText = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
    
    let containerRender = template.render[0].children[0];
    let iconRender = containerRender.children[0].children[0];
    let textRender = containerRender.children[1].children[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }
    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div className='w-fit flex' style={masterContainerStyle}>
            <div style={{marginTop:"4px",marginRight:"10px"}}>
                <VariableValueExtractor variableId={iconRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
                <VariableValueExtractor variableId={textRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default TextBoxContainerContainerIconText;