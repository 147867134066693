import axios from 'axios';
import { BLOB_URL, V_NUMBER } from '../utils/constants';

const options = {headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
    }
};

class ChapterManager {

    static async loadChaptersAsync(callback) {
        try {
            const response = await axios.get(`${BLOB_URL}chapters.json?v=${V_NUMBER}`, options);

            if(typeof callback === "function") callback(response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error loading Chapters data:', error);
            
            if(typeof callback === "function") callback(null);
            
            throw error;
        }
    }

    static async loadChapterDataAsync(chapterId, callback) {
        try {
            const response = await axios.get(`${BLOB_URL}${chapterId}.json?v=${V_NUMBER}`, options);
            if(typeof callback === "function") {
                callback(response.data);
            }
            
            return response.data;
        } catch (error) {
            console.error('Error loading Chapter data:', error);
            if(typeof callback === "function") callback(null);
            throw error;
        }
    }

    static async loadTemplateDataAsync(callback) {
        try {
            const response = await axios.get(`${BLOB_URL}templateData.json?v=${V_NUMBER}`, options);
            
            if(typeof callback === "function") callback(response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error loading Chapter data:', error);
            if(typeof callback === "function") callback(null);
            throw error;
        }
    }   
    
    static async loadTranslationsCardsAsync(callback) {
        try {
            const response = await axios.get(`${BLOB_URL}translations/translationscards.json?v=${V_NUMBER}`, options);

            if(typeof callback === "function") callback(response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error loading Translations Cards data:', error);
            
            if(typeof callback === "function") callback(null);
            
            throw error;
        }
    }

    static async loadTranslationsCtasAsync(callback) {
        try {
            const response = await axios.get(`${BLOB_URL}translations/translationsctas.json?v=${V_NUMBER}`, options);

            if(typeof callback === "function") callback(response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error loading Translations Ctas data:', error);
            
            if(typeof callback === "function") callback(null);
            
            throw error;
        }
    }

    static async loadTranslationsEnumsAsync(callback) {
        try {
            const response = await axios.get(`${BLOB_URL}translations/translationsenums.json?v=${V_NUMBER}`, options);

            if(typeof callback === "function") callback(response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error loading Translations Enums data:', error);
            
            if(typeof callback === "function") callback(null);
            
            throw error;
        }
    }
}

export default ChapterManager;