import {DEBUG, BASED_WIDTH, MAX_WIDTH} from './constants';

/**
 * Converts millimeters to pixels.
 * @param {number} mm - The value in millimeters to convert to pixels.
 * @returns {number} - The converted value in pixels.
 */
export const convertMMToPixels = (mm) => mm ? mm * 3.7795275591 : 0;
export const convertMMToPoints = (mm) => mm ? mm * 3.7795275591 : 0;

export const FONT_SIZE_MP = 2.5;

export const getRandomColor = () => {
    if(!DEBUG) return 'transparent';
    // color:
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return 'rgba(' + r  + ',' + g + ',' + b + ',0.2)';
}

export const convertXAbsolutePosition = (value, from) => {
  if (typeof window === "undefined") return value;
    if(!value) return value;

    const windowWidth = window.innerWidth <= MAX_WIDTH ? window.innerWidth : MAX_WIDTH;

    let result = (windowWidth * value) / BASED_WIDTH;
    return result > windowWidth * 0.90 ? windowWidth * 0.90 : result;
}

/**
 * Sorts an array of cards based on a specified order.
 * @param {Array} cards - The array of cards to sort.
 * @param {Array} order - The desired order of the cards.
 * @returns {Array} - The sorted array of cards.
 */
export const orderCards = (cards, order) => {
    // Create a copy of the cards array to avoid modifying the original array
    const sortCards = [...cards];
  
    // Sort the copied array based on the order array
    sortCards.sort((a, b) => {
      // Find the index of each card in the order array
      const indexA = order.findIndex((item) => item._id === a._id);
      const indexB = order.findIndex((item) => item._id === b._id);
  
      // If the index of the first card is less than the index of the second card, return -1 (indicating that the first card should come before the second card)
      if (indexA < indexB) return -1;
      // If the index of the first card is greater than the index of the second card, return 1 (indicating that the first card should come after the second card)
      if (indexA > indexB) return 1;
      // If the index of the first card is equal to the index of the second card, return 0 (indicating that the order of the two cards doesn't matter)
      return 0;
    });
  
    // Return the sorted array of cards
    return sortCards;
  };