import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveDisciplineLevelXSandboxV2 = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {



    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    const masterContainerStyle = {
        ...getContainerStyle("0b9e7a61-1973-4212-8b98-9df19a37262c", template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle("adb1e1f6-a261-44d7-b203-a01a6b0ca746", template, cards, card, variables, templateData, null),
        padding:'1px',
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap">
                <div className="flex items-center flex-nowrap text-nowrap">
                    <VariableValueExtractor variableId={"578fb528-9fd5-46e8-8d51-18ef4e85d867"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-1" style={childContainerStyle}>
                        <div className='p-0.5 flex'>
                            <VariableValueExtractor variableId={"2f8cf900-f4fc-41ae-b756-683fcc0fe8b9"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>

                            <VariableValueExtractor variableId={"c8a50bef-db27-4929-aab3-cae5ed816864"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                    <VariableValueExtractor variableId={"3467e028-ab2d-4fce-8901-7db1abd4097e"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                    <VariableValueExtractor variableId={"4647805d-824f-4652-ae79-73e8493bdddf"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                <div className="flex items-center flex-nowrap text-nowrap">
                    <VariableValueExtractor variableId={"f56c8cc4-820c-49e6-b864-032aeb424fc9"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>

                    <VariableValueExtractor variableId={"40112fa0-3cf4-4fac-9a34-759491e0cd8c"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div>
                {findModuleVariables("6011bfcf-c0fb-4e8e-8293-926b955cbc12", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouHaveDisciplineLevelXSandboxV2;