import * as Icons from './ChaptersIconSvgs/Icons';

const iconsDict = [
    {
        type:"I",
        values:  ["0", "1", "2", "3", "4", "5"]
    }
]

const findIcon = (letter) => {
    for(let iconType of iconsDict){
        if(iconType.values.indexOf(letter) > -1){
            return iconType.type + letter;
        }
    }

    return letter.toUpperCase();
}

const ChaptersIcons = ({ name, height = '50', width = '50', color = '#ffffff' }) => {
    if (!name) return <></>;

    let iconName = findIcon(name);

    if(!iconName) {
        console.error("could not find letter " + name);
        return <></>;
    }
  
    const IconComponent = Icons[iconName];

    if(!IconComponent){
        console.error("could not find icon " + iconName);
        return <></>;
    }
  
    return <IconComponent fill={color} height={height} width={width} />;
  };
  
  export default ChaptersIcons;