import { Link } from 'react-router-dom';
import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import Spacer from '../utils/Spacer';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const StaticMakeAAttributeSkillCheckWith2OutcomesBlackbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("80bc0057-1e9f-4ffa-927b-0aba5be18ed0", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    const getLinkId = (variableId, fallback) => {
        let value = variables[variableId]?.value;
        if(value) return value;

        return variables[fallback]?.value;
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 text-center w-full" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div>
                <VariableValueExtractor variableId={"9efc0611-b2ae-42e4-9f40-c6e4950da38c"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className='flex w-full align-center justify-center'>
                <VariableValueExtractor variableId={"393edb4a-1d16-4381-87dc-edbcc377cc0f"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <Spacer/>
                <Link to={`/${deckId}/${getLinkId("b7d979c0-8b16-4011-96a0-b26159758a5b", "07c52833-8f4e-465f-ba3d-be68f2595b1d")}`}>
                    <VariableValueExtractor variableId={"c413dcf8-dbde-4a68-b385-95f4f810ffc2"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </Link>
            </div>
            <div className='flex w-full align-center justify-center'>
                <VariableValueExtractor variableId={"618fe25c-b15c-4be3-bee8-c147fd524fee"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <Spacer/>
                <Link to={`/${deckId}/${getLinkId("c7589009-aa21-409f-8a51-a4b0008298e1", "81bddb7b-5ed3-4df0-adf7-bd42478817da")}`}>
                    <VariableValueExtractor variableId={"33cb1107-49df-47b7-b921-8c43ab33d3cf"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </Link>
            </div>

            {findModuleVariables("987f4d9f-b349-4b7a-bfdd-13013afd620d", variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}
                    />
                )
            })}
        </div>
    )
}

export default StaticMakeAAttributeSkillCheckWith2OutcomesBlackbox;