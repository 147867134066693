import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as XIcon } from '../icons/DefaultIconSvgs/X.svg'
import { ReactComponent as RuleBookIcon } from '../icons/DefaultIconSvgs/Download Rulebook.svg'
import { ReactComponent as VisitIcon } from '../icons/DefaultIconSvgs/Visit Website.svg'
import { ReactComponent as ContactIcon } from '../icons/DefaultIconSvgs/Contact us.svg'
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function MainMenu({
    menuOpen,
    setMenuOpen,
    chapters
}) {
    const [selectedChapter, setSelectedChapter] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleMenuClick = () => {
        if (menuOpen === null) {
            setMenuOpen(true);
            return;
        }
        setMenuOpen(!menuOpen);
    }

    const handleChapterSelection = (selection) => {
        setSelectedChapter(selection);
    }

    const handleLanguageSelection = (selection) => {
        i18n.changeLanguage(selection);
        navigate(0);
    }


    const handlePlayNow = () => {
        let cardId = chapters.find(_ => _.id === selectedChapter)?.firstCard;
        setMenuOpen(false);
        navigate(`/${selectedChapter}/${cardId}`);

    }


    return menuOpen ?
        (<div className='safearea modal-menu'>
            <div className='open-close-container'>
                <a className='open-close-button' href="#click"  onClick={() => handleMenuClick()}>
                    <XIcon height={50} width={50} fill="white"/>
                </a>
            </div>

            <div className='image-container'>
                <img className='header-image' src='/images/vmt-logo.webp' alt="1"></img>
            </div>

            <div className='select-container'>
                <select className='chapter-select' onInput={(ev) => { handleChapterSelection(ev.target.value) }}>
                    <option key={null}>Select a Chapter</option>
                    {chapters?.map((item, index) => {
                        return (
                            <option key={index}
                                value={item.id}
                            >
                                {item.name}
                            </option>
                        )
                    })}
                </select>
            </div>
            <div className='button-container'>
                {selectedChapter && (
                    <button className='default-button' onClick={() => { handlePlayNow() }}>
                        {t("play-now")}
                    </button>
                )}
            </div>

            <div className='full-line'></div>

            <div className='menu-items '>
                <div className='bubble-container' style={{ marginTop: '0px' }}>
                    <Link className="item underline" to={"/inventory"}>
                        <img src="/images/home/alex-inventory.webp" alt="2" />
                        {t("alex-inventory")}
                    </Link>
                    <Link className='item underline' to={"/feeding-event"}>
                        <img src="/images/home/feed.webp" alt="2" />
                        {t("feeding-events")}
                    </Link>
                </div>
            </div>

            {/* <div className='full-line'></div>

            <div className='select-container'>
                <select className='chapter-select' value={i18n.language} onInput={(ev) => { handleLanguageSelection(ev.target.value) }}>
                    <option value={"en"}>English</option>
                    <option value={"fr"}>Français</option>
                </select>
            </div> */}

            <div className='full-line'></div>

            <div className='menu-items mb-5'>
                <Link className='item-gray flex items-center' to={"https://www.vampirethemasquerade-chapters.com/rules/Chapters_Rulebook_VE.pdf"} target='_blank'>
                    <RuleBookIcon height={50} width={50} /> {t("download-rulebook")}
                </Link>
                <Link to={"https://www.flyosgames.com/"} target='_blank' className='item-gray flex items-center'>
                    <VisitIcon height={50} width={50} />  {t("visit-website")}
                </Link>
                <Link to={"mailto:info@flyosgames.com"} className='item-gray flex items-center'>
                    <ContactIcon height={50} width={50} /> {t("contact-us")}
                </Link>
            </div>
        </div>) : (<></>)

}