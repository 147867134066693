import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const InvestigationLeftEnd = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("5a4db4cd-b66c-42c3-afc4-a0d48e412836", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        backgroundImage:`url(${BLOB_URL}${findVariableOrDefaultToTempateValue("c5541b6a-cda5-48b4-9e86-4a06d8e314b3", template, cards, currentCard, currentCard.variablesValues, templateData)?.image}`
    }

    return (
        <div className="w-full h-full flex flex-col background-image" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow' >
                <div className='header flex'>
                    <div className='flex flex-col justify-between items-center w-full'>
                        <div className='flex flex-col my-5' style={{width: CONTENT_WIDTH}}>
                            <div className='flex'>
                            <VariableValueExtractor variableId={"b7b4f9c8-ad7d-499d-b66b-0cfc6b0c5077"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null}
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='ml-1' style={{color: 'black', fontSize: '18px', fontFamily: 'Oswald-SemiBold', fontWeight: '400', textTransform: 'uppercase'}}>
                            <VariableValueExtractor variableId={"4de54843-cfcc-4732-9245-1bec431ea3b7"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null}
                                ctaId={ctaId}
                                parentId={parentId}/>
                            </div>
                            </div>
                        </div>
                        <div className='full-to-fade-gradient' style={{height:"2px", width:"100%"}}></div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '3px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("a845a12c-ab57-466c-94fb-fb2fa374d4bd", template, cards, currentCard, currentCard.variablesValues, templateData)?.image
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                            <VariableValueExtractor variableId={"521a0c87-1458-432e-9fe8-fabf571b989f"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null}
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"4f4e308e-64df-4b1e-b95b-2112d71dabfe"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InvestigationLeftEnd;