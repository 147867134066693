import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const  IfYouHaveTheSkillLevelXSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    const masterContainerStyle = {
        ...getContainerStyle("873acb6f-6fc5-40df-879a-d475e00afe69", template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (
        
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap flex">
                 <VariableValueExtractor variableId={"cdd9ccd4-1046-4192-970b-5c0913d6582f"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
             </div>

            <div>
            {findModuleVariables("6011bfcf-c0fb-4e8e-8293-926b955cbc12", variables).map((variable, index) => {
                return (
                <ModuleSelector
                    key={index}
                    variable={variable}
                    card={card}
                    cards={cards}
                    deckId={deckId}
                    templateData={templateData}
                    fullWidth={true}
                />
                )
            })}
            </div>
            
        </div>
    )
}

export default IfYouHaveTheSkillLevelXSandbox;