import { BLOB_URL, CONTENT_WIDTH, V_NUMBER } from '../../utils/constants';
import { findVariableOrDefaultToTempateValue, getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const ImageInterlude = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let  image = "";
    if(template.render[0].type === "image"){
        image = template.render[0];
    }else if(template.render[1]?.type === 'image'){
        image = template.render[1];
    }else{
        image = template.render[0].children[0];
    }



    let imageUrl = null;

    if(image.variable){
        let variableValue = findVariableOrDefaultToTempateValue(image.variable, template, cards, card, variables, templateData, null)?.value;
        if(variableValue){
            imageUrl = variableValue;
        }
    }
    if(!imageUrl){
        imageUrl = findVariableOrDefaultToTempateValue(image.uuid, template, cards, card, variables, templateData, null).image;
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ width: fullWidth ? 'fit-content' : CONTENT_WIDTH}}>
            <img src={BLOB_URL + imageUrl + "?v=" + V_NUMBER}/>
        </div>
    )
}

export default ImageInterlude;