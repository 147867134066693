import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, findNestedModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { BLOB_URL, CONTENT_WIDTH, V_NUMBER, menuHeightStyle } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const GuessLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("46a4ca80-1615-4feb-9b54-22818ae29824", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        backgroundImage:`url(${BLOB_URL}${findVariableOrDefaultToTempateValue("527751ea-f99b-4adb-8b10-6395b8e2ce55", template, cards, currentCard, currentCard.variablesValues, templateData)?.image}`
    }

    const bottomContainerStyle = {
        ...getContainerStyle("3844afc2-a696-4232-9a3f-b5214db6ddbc", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto background-image" style={masterContainerStyle}>
            <div className='top-section flex flex-col' style={menuHeightStyle}>
                <div className='header flex'>
                    <div className='flex flex-col justify-between items-center w-full'>
                        <div className='flex flex-col my-5' style={{width: CONTENT_WIDTH}}>
                            <div className='flex'>
                            <VariableValueExtractor variableId={"c13d9359-b8a3-4f29-86c2-2786c68be46f"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null}
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='ml-1' style={{color: 'black', fontSize: '18px', fontFamily: 'Oswald-SemiBold', fontWeight: '400', textTransform: 'uppercase'}}>
                            <VariableValueExtractor variableId={"b0af775b-ba6b-4a07-9d54-48de4b6d762d"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            </div>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                        <div className='full-to-fade-gradient' style={{height:"2px", width:"100%"}}></div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>
            <div className='grow flex-col mt-10  pb-5'  style={bottomContainerStyle}>

                         
                <div className='relative line-section w-full justify-end fade-to-fade-gradient-black' style={{ height: '1.5px' }}>
                    <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Black.webp" + "?v=" + V_NUMBER} alt="ank" />
                </div>
                <div className='bottom-section'>
                    <div className='flex flex-col items-center mt-2'>
                        {findNestedModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard.variablesValues).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={currentCard}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    separatorColor={'black'}
                                />
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GuessLeft;