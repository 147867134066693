import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const  IfNpc1IsOnTheTileStorybook = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("873acb6f-6fc5-40df-879a-d475e00afe69", template, cards, card, variables, templateData, null),
        padding:"7px"
    }


    return (
        
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null, borderTop:"1px white solid", borderBottom:"1px white solid" }}>
            <div className="title flex items-center flex-wrap flex mb-2">
                 <VariableValueExtractor variableId={"ac99ceb1-5938-4b47-964d-128bf9a42dc2"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
             </div>

            <div>
            {findModuleVariables("cf1d4721-8a41-4b3c-a50b-f8e73e9f9369", variables).map((variable, index) => {
                return (
                <ModuleSelector
                    key={index}
                    variable={variable}
                    card={card}
                    cards={cards}
                    deckId={deckId}
                    templateData={templateData}
                    fullWidth={true}
                />
                )
            })}
            </div>
            
        </div>
    )
}

export default IfNpc1IsOnTheTileStorybook;