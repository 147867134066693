import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TextOnlyBlock = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"


    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    let textVariableId = template.render[0].variable;
    let textRenderId = template.render[0].uuid;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{width: !fullWidth ? CONTENT_WIDTH : null}}>
            <VariableValueExtractor variableId={textVariableId}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={textRenderId}
                isLink ={null}
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default TextOnlyBlock;