import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const MakeARouseCheckSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("3cd376e5-e89b-4055-8665-bed7653ff8c1", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="flex items-center">
                <VariableValueExtractor variableId={"87c79dbf-cbae-4f7a-b0c4-0a9153a1ccd7"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>


            <div className="flex flex-col mt-2" >
                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="P" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"fdfb3f39-2dd8-41c5-99f8-ee281f1752cd"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div style={{ marginTop: "-0.48rem" }}>
                        {findModuleVariables("f2b10b8a-13e1-4b5f-a752-8597142ca217", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"24c7f1c9-a236-425d-8a9a-64f887ddb9fb"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div style={{ marginTop: "-0.48rem" }}>
                        {findModuleVariables("1f73386f-8528-4e02-a321-007a9bf70b38", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="L" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"aef172fc-2b1a-4c7b-b24e-43d5fc0cd883"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div style={{ marginTop: "-0.48rem" }}>
                        {findModuleVariables("5e43a219-3984-4772-8b46-73e8f1271dcf", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakeARouseCheckSandbox;