import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findNestedVariable,getRenderColor, findModuleVariables, getContainerStyle, findNestedModuleVariables } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const EventActionEndWithBottomLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("ead2559c-ad2f-4c33-948e-ea4687653a19", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (

        //le h-full fait disparaitre le trait du milieu hcapitre 17 p42
        <div className="w-full h-fit flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div style={{height:"56px", marginTop:"-35px", backgroundColor:"white"}}>
                            <ChaptersIcons name={findNestedVariable("b5c8aef3-dd63-45ef-b9a2-6e9b79439b5c", currentCard.variablesValues)?.value}
                                color={getRenderColor("3451e3ca-50a1-466b-9b89-bf196b926c62", template.render, templateData)}
                                width='60px'
                                height='60px'/>
                            </div>
                            <div className='flex'>
                                <VariableValueExtractor variableId={"32573978-db3f-4d8b-aa52-cb65ba6b321a"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null}
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                                <div className='space' style={{ width: '5px' }}></div>
                                <VariableValueExtractor variableId={"e15473af-20b0-4c41-a8f2-09cd1620126a"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>
                            
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            />
                            )
                        })}

                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '3px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>

            <div className='flex flex-col items-center my-2'>
                {findNestedModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard.variablesValues).map((variable, index) => {
                return (
                    <ModuleSelector
                    key={index}
                    variable={variable}
                    card={currentCard}
                    cards={cards}
                    deckId={deckId}
                    templateData={templateData}
                    />
                    )
                })}
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("2be460b5-cf53-4fd4-81bb-7b2a8dfc9ab5", template, cards, currentCard, currentCard.variablesValues, templateData).image + "?v=" + V_NUMBER
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                            <VariableValueExtractor variableId={"41d0b97a-1e89-4723-88c4-4842bb082117"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null} 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"aa794392-8064-4d8e-80c4-8d3f23a8e22b"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null} 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EventActionEndWithBottomLeft;