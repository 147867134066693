export const DEFAULT_ORANGE_OVERLAY_CODE = "#9e763c";
export const DEFAULT_ORANGE_CODE = "#bb8a43";
export const FULL_TO_FADE_GRADIENT = "linear-gradient(90deg, rgba(187,138,67,1) 0%, rgba(187,138,67,1) 70%, rgba(255,255,255,1) 90%)"
export const SPECIAL_BOX_GRADIENT = "linear-gradient(rgb(239 237 237) 0%, rgba(255, 255, 255, 0) 50%)"

export const DEBUG = false;
export const LINES = false;
export const BASED_WIDTH = 342;
export const MAX_WIDTH = 645;
export const CONTENT_WIDTH = "90%";
export const V_NUMBER = 10;
// export const BLOB_URL = "https://app.vampirethemasquerade-chapters.com/data/";
export const BLOB_URL = "https://elysiumdu.blob.core.windows.net/flyos/";
export const menuHeightStyle = {paddingTop: "20px"};