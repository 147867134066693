import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouHaveDisciplineLevelXDoNotHaveEffectCardSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    
    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let topContainer = template.render[0];
    let topText = topContainer?.children[0]?.children[0]?.uuid;
    let bottomModule = topContainer?.children[2]?.variable;
    
    let childContainer = topContainer?.children[0]?.children[1]
    let childText = childContainer?.children[0]?.uuid
    let childLogo = childContainer?.children[1]?.uuid
    let childTextAfter = topContainer?.children[0]?.children[2]?.uuid

    let secondText = topContainer?.children[1]?.uuid


    const masterContainerStyle = {
        ...getContainerStyle(topContainer?.uuid, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(childContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap flex">
                <VariableValueExtractor variableId={topText}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                
                <div className="child-container flex mx-1" style={childContainerStyle}>
                    <div className='p-0.5 flex'>
                        <VariableValueExtractor variableId={childText}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>

                        <VariableValueExtractor variableId={childLogo}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
                <VariableValueExtractor variableId={childTextAfter}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
                <VariableValueExtractor variableId={secondText}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
            <div >
            {findModuleVariables(bottomModule, variables).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                fullWidth={true}
                            />
                        )
                    })}
            </div>
        </div>
    )
}

export default IfYouHaveDisciplineLevelXDoNotHaveEffectCardSandbox;