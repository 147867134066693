import { BLOB_URL, V_NUMBER } from "../../utils/constants";
import { convertMMToPixels, convertXAbsolutePosition } from "../../utils/libs";

const HexGrid = ({
  variable,
  template,
  card,
  variables,
  templateData
}) => {
  const tokens = templateData.tokens;

  const hexagonalGridCenters = (x, y, hexSize, alignTop) => {
    const centers = [];
    const hexHeight = Math.sqrt(3) * hexSize;

    for (let i = 0; i < x; i++) {
      let offsetY = 0;

      if (alignTop) {
        offsetY = i % 2 === 1 ? hexHeight / 2 : 0;
      } else {
        offsetY = i % 2 === 0 ? hexHeight / 2 : 0;
      }

      for (let j = 0; j < y; j++) {
        const centerX = i * 1.5 * hexSize;
        const centerY = j * hexHeight + offsetY;
        centers.push({ x: centerX, y: centerY, coordinate: { x: i, y: j } });
      }
    }

    return centers;
  };

  const findHexIndex = (col, row, x, y) => {
    if (col < 0 || col >= x || row < 0 || row >= y) {
      return 0;
    }
    return col * y + row;
  };

  const renderHexGrid = () => {
    let scale = (variables?.[variable]?.settings?.scale || 1);

    const canvasSize = {
      x: convertMMToPixels(
        variables?.[variable]?.settings?.canvasSize?.x || 50
      ),
      y: convertMMToPixels(
        variables?.[variable]?.settings?.canvasSize?.y || 30
      ),
    };

    const newX = convertXAbsolutePosition(canvasSize.x);
    const newScale = (newX / canvasSize.x);
    scale = scale * newScale;
    
    const newCanvasSize = {
      x:newX,
      y:canvasSize.y * newScale
    }

    const gridPosition = {
      x:
        convertMMToPixels(
          variables?.[variable]?.settings?.gridPosition?.x || 0
        ) * scale,
      y:
        convertMMToPixels(
          variables?.[variable]?.settings?.gridPosition?.y || 0
        ) * scale,
    };

    const gridSize = {
      x: variables?.[variable]?.settings?.gridSize?.x || 14,
      y: variables?.[variable]?.settings?.gridSize?.y || 8,
    };

    const hexSize = convertMMToPixels(
      variables?.[variable]?.settings?.hexagonSize || 5
    );

    const debug = variables?.[variable]?.debug || false;

    const tokensList = variables?.[variable]?.tokens || [];

    const startTop = variables?.[variable]?.settings?.start === "top" || false;

    const hexGridCenters = hexagonalGridCenters(
      gridSize.x,
      gridSize.y,
      (hexSize / 2) * scale,
      startTop
    );

    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          backgroundColor: "transparent",
          width: newCanvasSize.x,
          height: newCanvasSize.y,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            left:
              convertMMToPixels(
                variables?.[variable]?.settings?.imagePosition?.x || 0
              ) * scale,
            top:
              convertMMToPixels(
                variables?.[variable]?.settings?.imagePosition?.y || 0
              ) * scale,
            width:
              convertMMToPixels(
                variables?.[variable]?.settings?.imageSize?.width || 50
              ) * scale,
            height:
              convertMMToPixels(
                variables?.[variable]?.settings?.imageSize?.height || 30
              ) * scale,
          }}
        >
          <img
            className="w-full h-full"
            src={BLOB_URL + variables?.[variable]?.settings?.backgroundImage + "?v=" + V_NUMBER}
            alt="2"
          />
        </div>

        <div
          style={{
            display: "flex",
            position: "absolute",
            left:
              convertMMToPixels(
                variables?.[variable]?.settings?.imagePosition?.x || 0
              ) * scale,
            top:
              convertMMToPixels(
                variables?.[variable]?.settings?.imagePosition?.y || 0
              ) * scale,
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                left: gridPosition.x,
                top: gridPosition.y,
              }}
            >
              <div style={{ display: "flex", position: "relative" }}>
                {tokensList?.map((assetToken, index) => {
                  const center = findHexIndex(
                    assetToken.position.x,
                    assetToken.position.y,
                    gridSize.x,
                    gridSize.y
                  );
                  const imageSrc = tokens?.find(
                    (tok) => tok._id === assetToken.id
                  )?.image;
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        position: "absolute",
                        left: hexGridCenters[center].x,
                        top: hexGridCenters[center].y,
                        width: hexSize * scale,
                        height: hexSize * scale,
                        padding: 1,
                      }}
                    >
                      {imageSrc && (<img
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: `rotate(${assetToken.rotation}deg)`,
                        }}
                        src={BLOB_URL + imageSrc + "?v=" + V_NUMBER}
                        alt="5"
                      />)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderHexGrid()
  
}

export default HexGrid;