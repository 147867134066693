import {CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import ModuleSelector from "./_module-selector";

const IfYouAreSamuelArmstrongOrHaroldBeaulieuSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender.children[0].children[0];
    let imageRender = containerRender.children[0].children[1];
    let imageRender2 = containerRender.children[0]?.children[2];
    
  
    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-row justify-between items-center" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            
            <div>
            <VariableValueExtractor variableId={textRender.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            
            {findModuleVariables("a9c82cf3-fd90-45ec-94a9-1fd53105a307", variables).map((variable, index) => {
                return (
                    <ModuleSelector
                    key={index}
                    variable={variable}
                    card={card}
                    cards={cards}
                    deckId={deckId}
                    templateData={templateData}
                    fullWidth={true}
                    />
                    )
                })} 
            </div>
            

            <div className='flex self-start flex-col items-center'>
                <VariableValueExtractor variableId={imageRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div>
                    <VariableValueExtractor variableId={imageRender2?.uuid}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>
        </div>
    )
}

export default IfYouAreSamuelArmstrongOrHaroldBeaulieuSandbox;