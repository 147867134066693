import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const EventFailLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("eb06380b-d5fc-416b-8cf3-6b2c6494784b", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div className='flex'>
                                <VariableValueExtractor variableId={"daabefae-da9e-43fd-b044-47bcbfb51462"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null}
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                                <div className='space' style={{ width: '5px' }}></div>
                                <VariableValueExtractor variableId={"6a574f0a-db02-4a98-a0aa-a1f95c0a0417"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>

                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10 ' style={{ backgroundColor: "rgb(167, 32, 36)", height: '3px'}}>
                <div className='absolute w-2' style={{ left: 'calc(50% + -38px)', top: '-30px' }} >
                    <ChaptersIcons name="L"
                    color='rgb(167, 32, 36)'
                    width='80px'
                    height='80px'/>
                </div>
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("ce86f258-195e-40ab-b6a3-f3146fc2d4a3", template, cards, currentCard, currentCard.variablesValues, templateData)?.image + "?v=" + V_NUMBER
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                            <VariableValueExtractor variableId={"aaa5e823-7f7a-4b56-8b3d-45e140441ca0"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: "rgb(167, 32, 36)", height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Red.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"a1733252-7009-4aea-b1c1-13c41791a478"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EventFailLeft;