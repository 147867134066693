import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle} from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const  IfYouHaveXHungerSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    var topContainer = template.render[0].uuid;
    var topText = template.render[0].children[0].uuid;
    var bottomModule = template.render[0].children[1].children[0].variable;

    const masterContainerStyle = {
        ...getContainerStyle(topContainer, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap flex">
                <VariableValueExtractor variableId={topText}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>

            <div>
                {findModuleVariables(bottomModule, variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
             </div>
        </div>
    )
}

export default  IfYouHaveXHungerSandbox;