import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouWinOrIfYouLoseSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

   

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }


    let containerRender = template.render[0];

    let containerWinRender = containerRender.children[0];
    let textWinRender = containerWinRender.children[0]?.uuid;
    let ctaIdWinModule = containerWinRender.children[1].children[0]?.variable;

    let containerLoseRender = containerRender.children[2];
    let textLoseRender = containerLoseRender.children[0]?.uuid;
    let ctaIdLoseModule = containerLoseRender.children[1].children[0]?.variable;

    

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
       
    }

    const childContainerStyle = {
        ...getContainerStyle("fefa6444-ff13-4cdd-9084-ee840ad1e979", template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px",
    }



    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div style={{ ...childContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null, marginRight: "1rem"  }}>
                <VariableValueExtractor variableId={textWinRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div>
                {findNestedModuleVariables(ctaIdWinModule, variables)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                separatorColor={'black'}
                            />
                        )
                    })}
                </div>
            </div>

            <div style={{ ...childContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null, marginLeft: "1rem"  }}>
                <VariableValueExtractor variableId={textLoseRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div>
                {findNestedModuleVariables(ctaIdLoseModule, variables)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                separatorColor={'black'}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default IfYouWinOrIfYouLoseSandbox;