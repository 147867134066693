import { Link } from 'react-router-dom/dist';
import ModuleSelector from '../../../components/modules/_module-selector';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE, MAX_WIDTH, menuHeightStyle } from '../../../utils/constants';
import { findModuleVariables, findVariableOrDefaultToTempateValue, getContainerStyle, findRenderObjectByNameAndType } from '../../../utils/variable-extractor';

export default function FeedingEventPage({
    currentCard,
    template,
    templateData,
    cards,
    selectionOpen,
    setSelectionOpen,
    setIsPhysical
}) {


    const masterContainerStyle = {
        ...getContainerStyle("18301cc6-f745-4710-a5c7-97c0db05ddfa", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    let cityUuid =template.render[0].children.find(_=>_.name === "Right Side Section")?.children[1].children[0].uuid;
    let levelUuid = template.render[0].children.find(_=>_.name === "Right Side Section")?.children[1].children[1].uuid;

    var dialoguePageNumber = findRenderObjectByNameAndType("Dialogue Page Number", "text", template.render);
    var title = findRenderObjectByNameAndType("Title", "text", template.render);

    return <div className="w-full h-full flex flex-col bg-white overflow-y-auto" style={masterContainerStyle}>
        <div className='top-section flex flex-col grow'>
            <div className='header flex'>
                <div className='flex flex-col justify-between items-center w-full'>
                    <div className='flex flex-col my-5' style={{ width: CONTENT_WIDTH }}>
                        <div className='flex' style={{ color: DEFAULT_ORANGE_CODE, fontSize:"15px", fontFamily:"CrimsonPro-Bold" }}>
                            {findVariableOrDefaultToTempateValue(cityUuid, template, cards, currentCard, currentCard.variablesValues, templateData, null, null, true)}
                             <span className='mx-2'>-</span>
                            {findVariableOrDefaultToTempateValue(levelUuid, template, cards, currentCard, currentCard.variablesValues, templateData, null, null, true)}
                        </div>
                        <div className='flex'>
                            {findVariableOrDefaultToTempateValue(dialoguePageNumber.uuid, template, cards, currentCard, currentCard.variablesValues, templateData)}
                            <div className='ml-1' style={{ color: 'black', fontSize: '18px', fontFamily: 'Oswald-SemiBold', fontWeight: '400', textTransform: 'uppercase' }}>
                                {findVariableOrDefaultToTempateValue(title.uuid, template, cards, currentCard, currentCard.variablesValues, templateData)}
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                    <div className='full-to-fade-gradient' style={{ height: "2px", width: "100%" }}></div>
                </div>
            </div>

            <div className='flex items-center justify-center flex-col mt-2'>
                {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            templateData={templateData}
                        />
                    )
                })}
            </div>

        </div>
        <div className='relative w-full justify-end fade-to-fade-gradient mt-5' style={{ height: '2px' }}>
            <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp"} alt="ank" />
        </div>
        <div className='w-full flex flex-col justify-between' style={{ backgroundColor: "#050909" }}>

            <div className='flex flex-col self-center py-10 pl-5' style={{width: CONTENT_WIDTH}}>
                {/* <ul className='list-item' style={{ listStyleType: 'disc', color: DEFAULT_ORANGE_CODE }}>
                    <li>
                        <div style={{backgroundColor:DEFAULT_ORANGE_CODE, color:"white", padding:'7px', width:"fit-content"}}>PHYSICAL</div>
                    </li>
                </ul> */}
                {findModuleVariables("f49ae83c-5605-4a29-92c1-ed65a82a8b59", currentCard)?.filter(_ => [
                    "656cd95489231f88c3f6e679","656ce5e989231f88c3f75582"
                ].indexOf(_.ctaId) > -1)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            templateData={templateData}
                        />
                    )
                })}

                <div className='underline mt-3 cursor-pointer' style={{color:DEFAULT_ORANGE_CODE, fontFamily:"Oswald-Bold"}} onClick={() => {setSelectionOpen(true); setIsPhysical(true)}} >PICK THIS APPROACH</div>

                <div className='full-line' style={{maxWidth:"100%"}}></div>

                {findModuleVariables("3ae706f7-ed08-41b3-a1c2-26e3e4c55953", currentCard)?.filter(_ => [
                    "656cd95489231f88c3f6e679","656ce5e989231f88c3f75582"
                ].indexOf(_.ctaId) > -1)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            templateData={templateData}
                        />
                    )
                })}

                <div className='underline mt-3 cursor-pointer' style={{color:DEFAULT_ORANGE_CODE, fontFamily:"Oswald-Bold"}} onClick={() => {setSelectionOpen(true); setIsPhysical(false)}}>PICK THIS APPROACH</div>
            </div>

            <Link to={"/feeding-event"} className='text-center cursor-pointer' style={{ borderTop: `solid 2px ${DEFAULT_ORANGE_CODE}`, fontFamily: "Oswald-Bold", color: DEFAULT_ORANGE_CODE, padding: "15px" }}>
                BACK TO ALL DISCTRICT
            </Link>
            
        </div>
    </div>
}