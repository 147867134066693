import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle, getRenderColor, findNestedVariable } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import ClanIcons from '../icons/ClanIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveClueTokenXAndYouHaveDisciplineXLevelXSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let topContainer = template.render[0];
    let topText = topContainer.children[0].children[0].children[0].children[0]?.uuid;
    
    let childContainer = topContainer.children[0].children[0].children[1].children[0]
    let childText = childContainer.children[0]?.uuid
    let logoChild = childContainer.children[1]?.uuid

    let textLevel = topContainer.children[0].children[0].children[1].children[1].children[0]?.uuid

    let moduleCtaid = topContainer.children[1].children[0]?.variable

    const masterContainerStyle = {
        ...getContainerStyle(topContainer?.uuid, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(childContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: '1px',
        marginRight: '5px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='grow'>
                <VariableValueExtractor variableId={topText}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className='flex mt-1 flex-wrap '>
                    <div className="child-container flex " style={childContainerStyle}>
                    <VariableValueExtractor variableId={childText}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <VariableValueExtractor variableId={logoChild}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink={null} 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    </div> 
                    <VariableValueExtractor variableId={textLevel}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>

                <div>
                {findModuleVariables(moduleCtaid, variables)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                        />
                    )
                })}
                </div>
            </div>
            <div className='flex justify-start '>
                <ClanIcons name={findNestedVariable("2a68efac-315a-4660-a396-22dd654716f3", variables)?.value}   
                color={getRenderColor("dec5a3b1-2a90-4418-8230-8fa445bbb4d9", template.render, templateData)} />
            </div>
        </div>
    )
}

export default IfYouHaveClueTokenXAndYouHaveDisciplineXLevelXSandbox;