import { Link } from 'react-router-dom';
import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

import SpacerLine from '../utils/SpacerLine';

const StaticMakeAttributeSkillCheckDifficultyXSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    const masterContainerStyle = {
        ...getContainerStyle("3a5228ad-a4fc-4ecf-ba94-4c4cdbccd4fe", template, cards, card, variables, templateData, null),
        padding: "7px"
    }


    const childContainerStyle = {
        ...getContainerStyle("6e8def63-9e99-49e5-8849-55edbf0d3796", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    const successReadLink = variables["1fad1e37-8a52-4ae7-8589-c2adc60ba2f4"]?.value;
    const failureReadLink = variables["5c25bce7-20a2-4b58-9817-1e8a7e09ccf6"]?.value;

    return (
        <div className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="flex items-center">
                <VariableValueExtractor variableId={"41462c76-6e3d-425e-97ec-bd21b20713bb"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="child-container flex mx-1 p-1" style={{ ...childContainerStyle, flex: "0 0 auto" }}>
                <div className='p-0.5'>
                    <VariableValueExtractor variableId={"44208b3a-a7aa-46e1-ad1a-93a218fc67ff"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    </div>
                </div>
            </div>


            <div name={`${template._id} - ${template.name}`} className="flex mt-2 flex-wrap" >

                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-5px" }}>
                        <VariableValueExtractor variableId={"1fe5148b-8494-415c-807b-41b5a400579a"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div className="me-1">
                        <VariableValueExtractor variableId={"75f51148-9ce3-4d84-9497-4c84888ba14d"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div className='underline'>
                        <Link to={`/${deckId}/${successReadLink}`} style={{color:DEFAULT_ORANGE_CODE}}>
                            <VariableValueExtractor variableId={"9984890d-32d9-43fd-b528-5c466a143c9f"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </Link>
                    </div>
                </div>

                <div className="mx-2" style={{marginTop:"6px"}}>
                    <SpacerLine height={'12px'} />
                </div>

                <div className="flex items-center" style={{ flex: "0 0 auto" }}>
                    <div className="me-1" style={{ marginTop: "-5px" }}>
                        <VariableValueExtractor variableId={"8fe4cfa4-4a4d-453a-bcc3-ea03b3b13c7a"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div className="me-1">
                        <VariableValueExtractor variableId={"fe6e811d-72fb-4660-a990-4a3589efe1e5"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div className='underline'>
                        <Link to={`/${deckId}/${failureReadLink}`} style={{color:DEFAULT_ORANGE_CODE}}>
                            <VariableValueExtractor variableId={"ada61a01-de26-4f16-9cc0-e9e053d3113a"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaticMakeAttributeSkillCheckDifficultyXSandbox;