import './_templates.scss'
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const CoverBack = ({
    currentCard,
    template,
    cards,
    deckId, 
    templateData,
    ctaId,
}) => {
    
    const parentId = currentCard._id;
    const backgroundImage = findVariableOrDefaultToTempateValue("a7307ce0-3c84-4058-a0c1-bbcc285d0085",  template, cards, currentCard, currentCard.variablesValues, templateData)?.image;

    return (
        <div className="w-full h-full background-image bg-center bg-cover" style={{backgroundImage:`url(${BLOB_URL}${backgroundImage})`}}>
            <div className='w-full h-full background-overlay'>
                <div className='w-full h-full flex flex-col'>
                    <div className='flex grow items-center justify-center w-full'>
                        <img  width="100px" alt='back' src={BLOB_URL + findVariableOrDefaultToTempateValue("f9fb9dbe-2f0d-4967-8140-e90e3dda8d74",  template, cards, currentCard, currentCard.variablesValues, templateData)?.image + "?v=" + V_NUMBER}></img>
                    </div>
                    <div className='flex justify-center mb-10'>
                        <VariableValueExtractor variableId={"51a16d41-3b1c-48fc-b98e-69f541635e15"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoverBack;