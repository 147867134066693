import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const MakeAAttributeSkillCheckDifficultyXLongVersionSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    const masterContainerStyle = {
        ...getContainerStyle("1f216734-6d1b-402d-805c-699ff6199e66", template, cards, card, variables, templateData, null),
        padding: "7px"
    }


    const childContainerStyle = {
        ...getContainerStyle("89043392-bf75-4ea8-8d19-988fec49bdfe", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="flex items-center">
                <VariableValueExtractor variableId={"70f453da-d666-4d35-a53c-8b4adff2fc5c"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="child-container flex mx-1 p-1" style={{ ...childContainerStyle, flex: "0 0 auto" }}>
                <div className='p-0.5'>
                    <VariableValueExtractor variableId={"dde67f61-3d61-46f0-b33b-73d42e62d4f3"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                    </div>
                </div>
            </div>


            <div name={`${template._id} - ${template.name}`} className="flex flex-col mt-2" >

                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-5px" }}>
                        <VariableValueExtractor variableId={"1ed7f1b3-f03a-47ba-8b55-08ad94aa0708"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div className="me-1">
                        <VariableValueExtractor variableId={"672027bd-9b94-417d-832d-8461c0273e62"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables("5a028a26-20ad-4184-9052-2a022bc81027", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="flex items-center" style={{ flex: "0 0 auto" }}>
                    <div className="me-1" style={{ marginTop: "-5px" }}>
                        <VariableValueExtractor variableId={"8caf3408-15e4-470f-b17f-648f2b439842"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div className="me-1">
                        <VariableValueExtractor variableId={"2577ff46-2fb7-4b09-9693-93643ebb2735"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables("4a6cdde2-f071-450d-9bb9-ba596239284f", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakeAAttributeSkillCheckDifficultyXLongVersionSandbox;