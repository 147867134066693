import { CONTENT_WIDTH } from '../../utils/constants';
import { findNestedModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import ModuleSelector from './_module-selector';




const IfYouWinAnYouUsedAnyOfTheFollowingItenCards = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {
    
    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const containerRender = template?.render[0];
    const ctaVariableId = containerRender?.children[4]?.children[0]?.variable;

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }
    
    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div>
                <VariableValueExtractor variableId={containerRender?.children[0]?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={ containerRender?.children[1]?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={ containerRender?.children[2]?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={ containerRender?.children[3]?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                {findNestedModuleVariables(ctaVariableId, variables)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                        />
                    )
                })}
            </div>     
        </div>
    )
}

export default IfYouWinAnYouUsedAnyOfTheFollowingItenCards;