import { FONT_SIZE_MP, orderCards } from './libs';
import {findNestedVariable} from './variable-extractor';
import decks from '../_data/decks.json';


const allCards = [];
decks.forEach(element => {
    allCards.push(...element.cards);
});

export const getColor = (colorID, templateData) => {
    const colors = templateData.settings.colors;
    const color = colors.find((color) => color._id === colorID);
    if (!color) return colors.find((color) => color.isDefault)?.color;
    return color.color;
};

const getFont = (fontID, templateData) => {
    const fonts = templateData.settings.fonts;
    const font = fonts.find((font) => font._id === fontID);
    if (!font) return fonts.find((font) => font.isDefault)?.fontFamily;
    return font.name;
};

export const getStyle = (styleID, templateData, fontSize) => {
    const styles = templateData.settings.styles;
    const style = styles.find((style) => style._id === styleID);
    if (!style) return {};

    let lineHeight = null;
    let fontFamily = getFont(style.font, templateData);

    if(fontFamily){
        if( fontFamily === "DisciplineIcons"){
            lineHeight = style.fontSize * FONT_SIZE_MP + "px";
        }
        
        if(fontFamily === 'ChaptersIconsMix'){
            lineHeight = '1px';
        }
    }
    
    return {
        color: getColor(style.color, templateData),
        fontSize: !fontSize ? style.fontSize * FONT_SIZE_MP + "px" : fontSize,
        fontFamily: getFont(style.font, templateData),
        fontWeight: 400,
        lineHeight: lineHeight,
        textTransform: style.allCaps ? "uppercase" : "none",
    };
};

export const getPageNumber = (cardId, cards) => {
    // const decks = []; // FIX THIS
    const card = allCards.find((card) => card._id === cardId);
    if (!card) return 0;
    const deck = decks.find((deck) => deck._id === card.deck);
    if (!deck) return 0;

    const currentCardInDeck = deck.cards.filter((card) => card.deck === deck._id);

    const newSortCards = orderCards(currentCardInDeck, deck.cardsOrder);

    const cardNumber = newSortCards.findIndex((card) => card._id === cardId);
    return cardNumber + 1;
};

export const getPageName = (cardId, label, cards) => {
    const card = allCards.find((card) => card._id === cardId);
    if (!card) return label;

    return card.name;
};

export const getTokensNumber = (tokenId, templateData) => {
    const tokens = templateData.tokens;
    const tokensCategories = templateData.tokensCategories;

    const token = tokens.find((token) => token._id === tokenId);
    if (!token) return 0;
    const tokenCategory = tokensCategories.find(
        (tokenCategory) => tokenCategory._id === token.category
    );
    if (!tokenCategory) return 0;

    const currentTokenInCategory = tokens
        .filter((token) => token.category === tokenCategory._id)
        .sort(
            (a, b) =>
                tokenCategory?.tokensOrder.indexOf(a._id) -
                tokenCategory?.tokensOrder.indexOf(b._id)
        );

    const tokenNumber = currentTokenInCategory.findIndex(
        (token) => token._id === tokenId
    );
    return tokenNumber + 1;
};

export const getTokensName = (variableValue, label, templateData) => {
    const tokens = templateData.tokens;
    const token = tokens.find((token) => token._id === variableValue.value);
    if (!token) return label;

    const currentName = token?.names?.find(
        (name) => name.uuid === variableValue.nameUuid
    );

    if (currentName) {
        return currentName.name;
    }

    return "Missing name";
};

export const getDelimitationPageNumber = (cardId, cards) => {
    // const decks = []; // FIX THIS
    const card = cards.find((card) => card._id === cardId);
    if (!card) return 0;
    const deck = decks.find((deck) => deck._id === card.deck);
    if (!deck) return 0;

    let cardsOrder = deck.cardsOrder;

    cardsOrder = cardsOrder.filter((item) => {
        if (item.type === "delimitation") {
            return true;
        } else if (item.type === "card") {
            return cards.find((card) => card._id === item._id);
        }
        return false;
    });

    let count = 0;
    for (let i = 0; i < cardsOrder.length; i++) {
        const item = cardsOrder[i];

        if (item.type === "delimitation") {
            count = 0;
        } else if (item.type === "card") {
            count++;
            if (item._id === cardId) {
                break;
            }
        }
    }

    return count;
};

export const renderTextElement = (paragraphElement, index, templateData, isLink, noStyle) => {
    if (paragraphElement.marks !== undefined) {
        let elementStyle = getStyle(paragraphElement.marks[0].attrs.style, templateData);
       
        if(isLink && paragraphElement.text.indexOf("READ EVENT") > -1){
            let linkStyle = getStyle("64ef511917b7622c4d3239e7", templateData);
            elementStyle = {...elementStyle, color:linkStyle.color}
        }
        return (
            <span
                key={paragraphElement.marks[0].attrs.style + index}
                style={!noStyle ? { ...elementStyle } : null}
                className={isLink && (elementStyle?.color === "rgb(187, 138, 67)" || elementStyle?.color === "#bb8a43") ? 'underline' : ''}
            >
                {paragraphElement.text}
            </span>
        );
    }
    return paragraphElement.text;
};

const renderMentionText = (paragraphElement, index, cards, currentCardId, variables, templateData, isLink, noStyle) => {
    //TODO: get mention value from variable
    let mentionValue = `@${paragraphElement.attrs.label}`;

    if (paragraphElement.attrs?.type === "currentPage") {
        mentionValue = getPageNumber(currentCardId, cards);
    }

    if (paragraphElement.attrs?.type === "currentPageName" && currentCardId) {
        mentionValue = getPageName(currentCardId, mentionValue, cards);
    }

    if (paragraphElement.attrs?.type === "currentDelimitationPage") {
        mentionValue = getDelimitationPageNumber(currentCardId, cards);
    }

    if (paragraphElement.attrs?.type === "number") {
        mentionValue = "0";
    }

    const enumerators = templateData.settings.enumerators;
    const variable = findNestedVariable(paragraphElement.attrs.id, variables);

    if (variable) {
        const valueType = variable?.type;
        const value = variable?.value;

        switch (valueType) {
            case "string":
                mentionValue = value;
                break;

            case "number":
                mentionValue = value;
                break;

            case "enumerator":
                const enumerator = enumerators.find(
                    (enumerator) => enumerator._id === variable?.enumeratorId
                );
                if (enumerator) {
                    mentionValue = enumerator.values.find(
                        (value) => value.uuid === variable?.value
                    )?.name;
                } else {
                    mentionValue = "none";
                }
                break;

            case "token":
                if (paragraphElement.attrs?.type === "tokenNumber") {
                    mentionValue = getTokensNumber(value, templateData);
                }

                if (paragraphElement.attrs?.type === "tokenName") {
                    mentionValue = getTokensName(variable, mentionValue, templateData);
                }
                break;

            case "page":
                if (paragraphElement.attrs?.type === "pageNumber") {
                    mentionValue = getPageNumber(value, cards);
                }

                if (paragraphElement.attrs?.type === "pageName") {
                    mentionValue = getPageName(value, mentionValue, cards);
                }
                break;

            case "delimitationPage":
                mentionValue = getDelimitationPageNumber(value, cards);
                break;

            default:
                break;
        }
    }

    let elementStyle = getStyle(paragraphElement.attrs?.style, templateData);
    return (
        <span
            key={paragraphElement.attrs?.id + index}
            style={!noStyle ? { ...elementStyle } : null}
            className={isLink && (elementStyle?.color === "rgb(187, 138, 67)" || elementStyle?.color === "#bb8a43") ? 'underline' : ''}
        >
            {mentionValue}
        </span>
    );
};

const renderParagraphElement = (paragraphElement, index, cards, currentCardId, variables, templateData, isLink, noStyle) => {
    switch (paragraphElement.type) {
        case "text":
            return renderTextElement(paragraphElement, index, templateData, isLink, noStyle);
        case "mention":
            return renderMentionText(paragraphElement, index, cards, currentCardId, variables, templateData, isLink, noStyle);
        default:
            return null;
    }
};

const renderParagraph = (element, index, renderStyle, cards, currentCardId, variables, templateData, isLink, noStyle) => {
    if (element.content === undefined || element.content.length === 0) {
        return <br key={index} />;
    }

    const renderText = element.content.map((element, i) =>
        renderParagraphElement(element, i, cards, currentCardId, variables, templateData, isLink, noStyle)
    );

    let elementStyle = getStyle(renderStyle, templateData);

    return (
        <p
            key={index}
            style={!noStyle ? {
                ...getStyle(renderStyle, templateData)
            } : null}
            className={isLink && (elementStyle?.color === "rgb(187, 138, 67)" || elementStyle?.color === "#bb8a43") ? 'underline' : ''}
        >
            {renderText}
        </p>
    );
};

export const generateText = (jsonText, renderStyle, cards, currentCardId, variables, templateData, isLink, noStyle) => {
    if (!jsonText?.content) {
        return null;
    }

    const content = jsonText.content.map((element, index) =>
        element.type === "paragraph" ? renderParagraph(element, index, renderStyle, cards, currentCardId, variables, templateData, isLink, noStyle) : null
    );

    return content;
};