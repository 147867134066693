import './_templates.scss'
import { findModuleVariables, findVariableOrDefaultToTempateValue, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import { FONT_SIZE_MP } from '../../utils/libs';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const PrologueConclusion = ({
    currentCard,
    template,
    cards,
    templateData,
    deckId,
    ctaId,
}) => {

    const parentId = currentCard._id;

    let backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("79ab1bb6-fef2-4bca-a384-fb23bc33ace9", template, cards, currentCard, currentCard.variablesValues, templateData)?.value;

    const bottomContainerStyle = {
        ...getContainerStyle("6585ad3b-e191-4229-8aa5-a1c6b14b86dd", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        padding: "7px",
        width: CONTENT_WIDTH,
        marginTop: "20px"
    }

    return (
        <div className="cover-prologue-front w-full h-full flex flex-col bg-center bg-cover overflow-y-auto force-font-size items-center py-10"
            style={{ backgroundImage: `url(${backgroundImage})`, paddingTop:"50px", paddingBottom:"20px" }}
        >
                <div className='w-full text-center'>
                    <h5 style={{ fontFamily: "CrimsonPro", color: DEFAULT_ORANGE_CODE, fontWeight: "bold", fontSize: `${8 * FONT_SIZE_MP}px` }}>
                        <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </h5>
                    <h1 style={{ fontFamily: "Oswald-Bold", color: "#f1e4d7", fontWeight: "bold", fontSize: `${20 * FONT_SIZE_MP}px` }}>
                        CONCLUSION
                    </h1>
                </div>

                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                <div style={bottomContainerStyle}>
                    <VariableValueExtractor variableId={"a63538d6-9bc8-4451-9d08-55bcadf69538"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId}/>
                    {findModuleVariables("e56dc428-7ed2-4811-bbb3-24b25fdb05fc", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                noStyle={true}
                            />
                        )
                    })}
                </div>
        </div>
    )
}

export default PrologueConclusion;