import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle, findNestedModuleVariables } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const EventEndLeftWithBottom = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("a72839d0-a3a4-41e7-93f4-6535d3e1b173", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (

        <div className="w-full h-full flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        
                            <div className='flex'>
                                <VariableValueExtractor variableId={"53454025-3185-488c-8c1b-4715107f97c3"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null}
                                    ctaId={ctaId}
                                    parentId={parentId}/>                    
                                <div className='space' style={{ width: '5px' }}></div>
                                <VariableValueExtractor variableId={"b0523fa9-35df-4553-af48-737363a1b535"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>

                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '3px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>

            <div className='flex flex-col items-center my-2'>
                {findNestedModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard.variablesValues).map((variable, index) => {
                return (
                    <ModuleSelector
                    key={index}
                    variable={variable}
                    card={currentCard}
                    cards={cards}
                    deckId={deckId}
                    templateData={templateData}
                    />
                    )
                })}
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image pt-3' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("00ee470f-23eb-4616-88f9-6fb719a01b71", template, cards, currentCard, currentCard.variablesValues, templateData).image
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                            <VariableValueExtractor variableId={"aa465c7b-d95e-4541-a702-4ab549e7e53b"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null} 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"e06eb7ef-07f3-4b3c-8d77-85fe4aa4596e"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EventEndLeftWithBottom;