import './_templates.scss'
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const SectionDialogue = ({
    currentCard,
    template,
    cards, 
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const backgroundImage = BLOB_URL +  findVariableOrDefaultToTempateValue("e98c1054-6f54-4b73-b0c2-438a929106a2",  template, cards, currentCard, currentCard.variablesValues, templateData).image;

    return (
        <div className="cover-prologue-front w-full h-full flex-col bg-center bg-cover flex justify-center items-center"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="flex flex-col items-center w-full px-4">
                <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-1"/>
                <VariableValueExtractor variableId={"c95dd988-84ed-4b51-b6ad-2297ed9427c5"}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    variables={currentCard.variablesValues}
                    templateData={templateData}
                    renderId={null}
                    isLink={null}
                    ctaId={ctaId}
                    parentId={parentId}/>
                <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-2"/>
            </div>
        </div>
    )
}

export default SectionDialogue;