import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfNpc1Npc2Npc3AreOnTheTile = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("78054282-ba6b-4e4e-887f-cf8339c5bf0e", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 w-full" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div>
                <VariableValueExtractor variableId={"3b540a42-e495-4b71-b5bb-342ce0d84074"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
            </div>


             {findModuleVariables("c495fd56-15cf-46b0-a804-7198972e835c", variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}
                    />
                )
            })} 
        </div>
    )
}

export default IfNpc1Npc2Npc3AreOnTheTile;