import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle} from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHavetheXItemCardChapterFailBlackboxMultitext = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {
    
    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }
    
    let containerRender = template.render[0];
    let firstText = containerRender.children[0].children[0].uuid
    
    const masterContainerStyle = {
        ...getContainerStyle("dcc1b9b0-a641-42bd-9bd6-36f68db9a711", template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex"style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="grow">
                <VariableValueExtractor variableId={firstText}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={"663e938b-e2e4-493a-a26d-65db9dfb9a4a"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                
                <VariableValueExtractor variableId={"4fbf4cdd-a039-46e1-849d-3fcf07b1fe30"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                
            </div>
            <div className='flex items-center'>
                <div className='mt-2' style={{width:'69px', height:'20px', marginTop: '50px'}}>
                    <VariableValueExtractor variableId={"478c0705-f330-40bc-b1cf-f41b58b8e9cd"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>
        </div>
    )
}

export default IfYouHavetheXItemCardChapterFailBlackboxMultitext;