import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const YouMustEachMakeAttributeSkillCheckTableDifficulty = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    let containerRender = template.render[0];
    let textRender = containerRender.children[0].children[0];
    

    const masterContainerStyle = {
        ...getContainerStyle("94a6f248-eabc-4724-9f1d-238d484718bd", template, cards, card, variables, templateData, null),
        padding: "7px"
    }
    const childContainerStyle = {
        ...getContainerStyle("e4030557-0909-4be6-8851-66b2eef8aeb1", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    const childGreyContainerStyle = {
        ...getContainerStyle("e5e16861-319f-4a3a-b00e-fa79cf17e1de", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <VariableValueExtractor variableId={textRender.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <div className="flex items-center">
                <div className="child-container mx-1" style={{ ...childContainerStyle, width: "100%" }}>
                    <div className='p-0.5 flex justify-between' style={{flex: "0 0 auto" }}>
                        <div className='flex items-end'>
                            <VariableValueExtractor variableId={"e6f7e44f-52df-424f-98b3-535a19e6e286"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                        <div>
                            <VariableValueExtractor variableId={"f08d13fc-713e-4b25-9389-3f6dada66e20"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>

                            <VariableValueExtractor variableId={"d4f9cb41-6246-4df6-a2c7-59758b739593"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                        <div>
                            <VariableValueExtractor variableId={"33abda4e-807d-456d-a655-9cd3394ff4ce"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <VariableValueExtractor variableId={"13852e8b-4e1e-443b-8002-75224dd4f110"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                        <div>
                            <VariableValueExtractor variableId={"dec6ab5e-b419-44a5-8633-588035a5f272"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                             <VariableValueExtractor variableId={"776dd606-7fab-4738-bc31-b9a53c534099"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                        <div>
                            <VariableValueExtractor variableId={"ede2d8cc-8c72-4c52-b076-6e36adb36ef5"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <VariableValueExtractor variableId={"74978452-73bc-4532-b5ff-2e8961f4016a"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center flex-wrap my-2"  style={{ ...childGreyContainerStyle, flex: "0 0 auto", }}></div>
            <div className="flex items-center">
                <div className="me-1" style={{ marginTop: "-3px", marginLeft: "-5px" }}>
                    <ChaptersIcons name="P" color='black' width={25} height={25} />
                </div>
                    <VariableValueExtractor variableId={"9bb5707d-a88a-4c21-bee9-1324a22b13c9"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
            </div>
            <div className="flex items-center flex-wrap" >
            
                <div className="flex items-center">
        
                    <div style={{ marginTop: "-0.50rem" }}>
                        {findModuleVariables("5a028a26-20ad-4184-9052-2a022bc81027", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
             </div>
             <div>
                <div className="flex items-center flex-wrap my-2"  style={{ ...childGreyContainerStyle, flex: "0 0 auto", }}></div>
                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "0px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <VariableValueExtractor variableId={"f2b61da2-3d29-47fc-afa7-2ac2dfc02bdf"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{ marginTop: "-0.50rem" }}>
                    {findModuleVariables("4a6cdde2-f071-450d-9bb9-ba596239284f", variables).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={card}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                fullWidth={true}
                            />
                        )
                    })}
                </div>
            </div>            
        </div>
    )
}

export default YouMustEachMakeAttributeSkillCheckTableDifficulty;