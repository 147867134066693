import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from '../utils/VariableValueExtractor';
const TemplateInterludeTimeheaderVersion = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if(variables.variablesValues){
        variables = variables.variablesValues;
    }
    
    return (
        <div name={`${template._id} - ${template.name}`} className="my-1" style={{width: !fullWidth ? CONTENT_WIDTH : null}}>
            <VariableValueExtractor 
                variableId={"5900c64c-36a3-47f1-9819-6567ed13c5a5"} 
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                ctaId={ctaId}
                parentId={parentId}
                renderId={"9bf30586-ff66-4110-8e8c-a31dac74df2a"}
                />
        </div>
    )
}

export default TemplateInterludeTimeheaderVersion;