import './_templates.scss'
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const CoverPrologueBack = ({
    currentCard,
    template,
    cards,
    deckId, 
    templateData,
    ctaId,
    eventPage
}) => {

    const parentId = currentCard._id;
    
    const backgroundImage = findVariableOrDefaultToTempateValue("c202f098-d5bb-403c-9e5d-47091d2e5f46",  template, cards, currentCard, currentCard.variablesValues, templateData).image;

    return (
        <div className="w-full h-full background-image" style={{backgroundImage:`url(${BLOB_URL}${backgroundImage})`}}>
            <div className='w-full h-full background-overlay'>
                <div className='w-full h-full flex flex-col'>
                    <div className='flex grow items-center justify-center w-full'>
                        <img  width="100px" alt='back' src={BLOB_URL + findVariableOrDefaultToTempateValue("12b2adc0-f6da-41b0-a2cf-8ae4fd523dbf",  template, cards, currentCard, currentCard.variablesValues, templateData).image + "?v=" + V_NUMBER}></img>
                    </div>
                    <div className='flex justify-center mb-10'>
                        <VariableValueExtractor variableId={"46856195-c00d-43ff-8f7f-3c75c682eea9"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoverPrologueBack;