import * as Icons from './ClanIconSvgs/Icons';


const findIcon = (letter) => {
    return letter.toUpperCase();
}

const ClanIcons = ({ name, height = '50', width = '50', color = '#ffffff' }) => {
    if (!name) return <></>;

    let iconName = findIcon(name);

    if(!iconName) {
        console.error("could not find letter " + name);
        return <></>;
    }
  
    const IconComponent = Icons[iconName];

    if(!IconComponent){
        console.error("could not find icon " + iconName);
        return <></>;
    }
  
    return <IconComponent fill={color} height={height} width={width} />;
  };
  
  export default ClanIcons;