import { getContainerStyle } from '../../utils/variable-extractor';
import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../utils/constants';
import { Link } from "react-router-dom";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const Chapter16TableBlackbox = ({ 
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }


    const masterContainerStyle = {
        ...getContainerStyle("0e61444e-2be2-4707-b5c7-9752aa907ed7", template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    const bigChildContainerStyle = {
        ...getContainerStyle("0e653634-7bdc-492b-82f1-ebb64f717ab0", template, cards, card, variables, templateData, null),
        marginTop:"3px", 
        marginBottom:"3px"
    }

    const childContainerStyle = {
        ...getContainerStyle("841e28e3-9031-4c9d-8db3-1e523140eafd", template, cards, card, variables, templateData, null),
        paddingTop:"1px",
        paddingBottom:"1px",
        width: "21px",
        textAlign: "center"
    }

    const childContainerStyle2 = {
        ...getContainerStyle("77be3ff3-e29a-42da-83a9-ff336aa4621f", template, cards, card, variables, templateData, null),
        paddingTop:"1px",
        paddingBottom:"1px",
        paddingLeft:"7px",
        paddingRight:"7px",
        width: "100%"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            
            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"835c5e38-e7ab-4567-a8f8-f7b414b344e6"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>

                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"3803babd-8748-4535-9f1d-f19874e35666"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
               
                </div>
            </div>


            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"c2046882-ccf8-419d-8351-c8e4d2ffa4ec"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"e36c65ac-b823-4fd2-bd29-06124d1df999"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"170b8778-7d54-4469-bf86-7c5effda4fe6"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"bf95c3a1-bb2e-457f-bfb7-05c17026a8f0"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>


            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"740009cc-48a8-4d63-acf5-4f8da5cbe494"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/> 
                </div>
                <div style={{...childContainerStyle2}}>
                    <Link to={`/${deckId}/${"65527b3e451d7e555cb1e780"}`} style={{color:DEFAULT_ORANGE_CODE}}>
                        <VariableValueExtractor variableId={"286ae83e-7122-4b1f-a60b-acb2de40275b"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/> 
                    </Link>
                </div>
            </div>
           
            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"d10a8b64-5d38-428b-9ce3-dff469f26486"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/> 
                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"b77fe154-c2e6-4236-aad4-b108a63e1e3a"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"58955f29-3967-45a5-a54b-b933185422bd"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/> 
                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"4f9e2e16-7d40-4069-bf9f-517e58e05bbc"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"3352f88d-d5a2-47ee-9e85-e6a7d9ca15d5"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"a6b8c580-6042-41c2-99cc-a03a9a1971fd"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"2549eabf-c157-47f8-a312-485e025e9535"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{...childContainerStyle2}}>
                    <Link to={`/${deckId}/${"65527e94451d7e555cb1f9e4"}`} style={{color:DEFAULT_ORANGE_CODE}}>
                        <VariableValueExtractor variableId={"c7b7feb9-da12-474b-bd70-3baed028809a"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </Link>
                </div>
            </div>

            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"ce8ef54f-82e7-445e-91f4-40ecc0288bbc"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{...childContainerStyle2}}>
                    <VariableValueExtractor variableId={"912ed9eb-7c50-4d48-9928-b21f494d721a"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div className=' flex items-center' style={{ ...bigChildContainerStyle}}>
                <div style={{...childContainerStyle}}>
                    <VariableValueExtractor variableId={"2549eabf-c157-47f8-a312-485e025e9535"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div style={{...childContainerStyle2}}>
                    <Link to={`/${deckId}/${"65527e5c451d7e555cb1f87f"}`} style={{color:DEFAULT_ORANGE_CODE}}>
                        <VariableValueExtractor variableId={"bc32d927-114d-4809-a4f8-5cb6c17b38e8"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </Link>
                </div>
            </div>


        </div>
    )
}

export default Chapter16TableBlackbox;