import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const HavetheEffectCardChapterFailBlackboxMultitext = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender?.children[0]?.children[0]?.uuid;
    let textRender1 = containerRender?.children[0]?.children[1]?.uuid;
    let textRender2 = containerRender?.children[0]?.children[2]?.uuid;

    let logo = containerRender?.children[1]?.children[0]?.uuid;


    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"10px",
        paddingBottom:"10px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex justify-between" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div>
                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={textRender1}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={textRender2}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
           </div>
           
            <div className=' pt-12' >
                <VariableValueExtractor variableId={logo}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default HavetheEffectCardChapterFailBlackboxMultitext;