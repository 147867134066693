import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const CombatBeginsGoldtext = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
    
    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 ml-2" style={{width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <VariableValueExtractor variableId={"a8a9c896-a5c6-4665-a752-4b41e5f3e1bf"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink={null} 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default CombatBeginsGoldtext;