import ModuleSelector from './_module-selector';
import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveUsedBiteCombatCardOrAnyDiscipline = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    
    let containerRender = template.render[0];
    let textRender = containerRender?.children[0]?.uuid;
    let ctaVariableId = containerRender?.children[1]?.children[0]?.variable;
    let lineStyle = containerRender?.children[2]?.uuid;
    let textRender2 = containerRender?.children[3]?.uuid;
    let ctaVariableId2 = containerRender?.children[4]?.children[0]?.variable;

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(lineStyle, template, cards, card, variables, templateData, null),
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <VariableValueExtractor variableId={textRender}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <div>
                {findNestedModuleVariables(ctaVariableId, variables)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                        />
                    )
                })}
            </div>

            <div className='relative line-section w-full justify-end ' style={{...childContainerStyle,  height: '1px' }}></div>
                <VariableValueExtractor variableId={textRender2}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            <div>
                {findNestedModuleVariables(ctaVariableId2, variables)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouHaveUsedBiteCombatCardOrAnyDiscipline;