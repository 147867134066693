import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedVariable,getRenderColor } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfNpcisAdjacenttoYouChapterFailBlackboxMultitext = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex justify-between " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div>
                <VariableValueExtractor variableId={"869e0223-f0fd-4d74-b6d0-1e61095e8588"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <VariableValueExtractor variableId={"e027b529-f9df-4ced-ba75-191e786d4dae"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <VariableValueExtractor variableId={"b47bd6d9-eca2-4e53-8b5a-893d4b2bf1b0"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div  style={{width:'69px', height:'50px'}}>
                <ChaptersIcons  name={findNestedVariable("65b3e6e7-3798-4ca7-a161-626206188e3d", variables)?.value}
                color={getRenderColor("d45e8b67-b8a9-4f96-b57c-b166e637f036", template.render, templateData)}
                width='80px'
                height='80px'/>
            </div>
        </div>
    )
}
export default IfNpcisAdjacenttoYouChapterFailBlackboxMultitext;