const Spacer = ({
    height,
    width
}) => {

    const style = {
        height: height ? height : '1px',
        width: width ? width : '5px'
    }
    
    return (
        <div style={style}></div>
    )
}

export default Spacer;