import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouHaveRangedWeaponItemCardAndEitherDisciplineLevelX = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender?.children[0]?.children[0]?.uuid;
    let containerChildRender = containerRender?.children[0]?.children[1]?.children[0];
    let childText = containerChildRender?.children[0]?.uuid
    let childLogo = containerChildRender?.children[1]?.uuid
    let childTextAfter = containerRender?.children[0]?.children[1].children[1].uuid;



    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(containerChildRender.uuid, template, cards, card, variables, templateData, null),
        padding:'1px',
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap">
                <div className='mr-1'>
                    <VariableValueExtractor variableId={textRender}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div className="flex items-center flex-wrap">
                    <div className="child-container flex" style={childContainerStyle}>
                        <div className='flex'>
                            <VariableValueExtractor variableId={childText}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            
                            <VariableValueExtractor variableId={childLogo}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink={null} 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                    <div className='ml-1'>
                        <VariableValueExtractor variableId={childTextAfter}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>
            <div>
                {findModuleVariables("6011bfcf-c0fb-4e8e-8293-926b955cbc12", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouHaveRangedWeaponItemCardAndEitherDisciplineLevelX;