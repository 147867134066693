import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, findNestedModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const DialogueLeftTight = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    let bottomContainerColor = template.render[0]?.children[1]?.uuid
    
    const masterContainerStyle = {
        ...getContainerStyle("fc89dde6-2518-4488-98cc-1197ef1d2f21", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    const topLeftImage = findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48", template, cards, currentCard, currentCard.variablesValues, templateData);

    const bottomContainerStyle = {
        ...getContainerStyle(bottomContainerColor, template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto" style={masterContainerStyle}>
            <div className='top-section flex flex-col'>
                <div className='header flex'>
                    <div className='flex background-image' style={{width:"100px", height:"100px", backgroundImage:`url(${BLOB_URL}${topLeftImage.value})`}}>
                        
                    </div>
                    <div className='flex flex-col justify-between w-full'>
                        <div></div>
                        <div className='flex flex-col ms-10'>
                            <div className='flex'>
                                <VariableValueExtractor variableId={"3a9f83fe-250b-41ce-90b6-1358daa47d97"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null} 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                                <VariableValueExtractor variableId={"9c41a43d-ce9e-4f23-8c52-a3c42f6a1398"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={"3a9f83fe-250b-41ce-90b6-1358daa47d97"}
                                    isLink={null} 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>
                            <div>
                            <VariableValueExtractor variableId={"7432ec46-3d4a-4d8c-b838-a1f95fa342a6"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                        <div className='full-to-fade-gradient' style={{height:"2px", width:"100%"}}></div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>
            <div className='grow flex-col mt-10 pb-5'  style={bottomContainerStyle}>
                <div className='relative line-section w-full justify-end fade-to-fade-gradient' style={{ height: '1.5px' }}>
                    <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                </div>
                <div className='bottom-section'>
                    <div className='flex flex-col items-center mt-0'>
                        {findNestedModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard.variablesValues).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={currentCard}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                />
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DialogueLeftTight;