/* eslint-disable react-hooks/exhaustive-deps */
import './_home.scss';

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import {ReactComponent as BarsIcon} from '../components/icons/DefaultIconSvgs/Menu.svg'
import ChapterManager from '../managers/chapter-manager'; 
import MainMenu from '../components/utils/MainMenu';


const Home = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const redirect = params.get('uri');
    const [menuOpen, setMenuOpen] = useState(false);
    const [chapters, setChapters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {

        if (redirect) {
            navigate(redirect);
        }
        
    }, [params, redirect]);

    useEffect(() => {
        ChapterManager.loadChaptersAsync((e) => {
            if(e === null) setChapters([]);
            else setChapters(e);
            setLoading(false);
        });
    }, [])

    const handleMenuClick = () => {
        if (menuOpen === null) {
            setMenuOpen(true);
            return;
        }
        setMenuOpen(!menuOpen);
    }

    const handleChapterSelection = (selection) => {
        setSelectedChapter(selection);
    }

    const handlePlayNow = () => {
        let cardId = chapters.find(_ => _.id === selectedChapter)?.firstCard;
        navigate(`/${selectedChapter}/${cardId}`);
    }
    
    const alexImage = '/images/home/alex-inventory.webp';
    //const feedingImage = '/images/home/feeding-event.webp';
    const feedingImage = '/images/home/feed.webp';


    return (

        <div className='background-image'>
            <div className='background-overlay'>
                <div className='safearea home'>
                {!menuOpen && (<div className='open-close-container'>
                        <a className='open-close-button' href="#1"  onClick={() => handleMenuClick()}>
                            <BarsIcon width={50} height={50} fill="white"/>
                        </a>
                    </div>)}
                    <img className='header-image' src='/images/home-header.webp' alt="1"></img>
                    <p className='app-description'>
                        <Trans i18nKey="app-description"></Trans>
                        
                    </p>
                    <select className='chapter-select' onInput={(ev) => { handleChapterSelection(ev.target.value) }}>
                        <option key={null}>{loading ? "Loading chapters... ": "Select a Chapter"}</option>
                        {chapters?.map((item, index) => {
                            return (
                                <option key={index}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            )
                        })}
                    </select>
                    <div className='button-container'>
                        {selectedChapter && (
                            <button className='default-button' onClick={() => { handlePlayNow() }}>
                                {t("play-now")}
                            </button>
                        )}
                    </div>
                    <div className='bubble-container'>
                        <Link className="item underline" to={"inventory"}>
                            <img src={alexImage} alt="2" />
                        {t("alex-inventory")}
                        </Link>
                        <Link className='item underline' to={"feeding-event"}>
                            <img src={feedingImage} alt="2" />
                        {t("feeding-events")}
                        </Link>
                    </div>
                </div>

                {menuOpen &&
                    (<MainMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} chapters={chapters}></MainMenu>)}
            </div>
        </div>

    );
}

export default Home;