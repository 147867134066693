import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const MakeThe5SkillChecksBelowEachCheckCanBeAttemptedOnlyOnce = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    var topContainer = template.render[0]?.uuid;
    var topText = template.render[0]?.children[0]?.uuid;


    const masterContainerStyle = {
        ...getContainerStyle(topContainer, template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap flex">
                <VariableValueExtractor
                    variableId={topText}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />
            </div>

            <div className='flex items-center gap-2 mt-2'>
                <VariableValueExtractor
                    variableId={"8e2f023b-0637-49b5-a415-846f03bb1e11"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />

                <div className="child-container flex my-1" style={{ ...getContainerStyle("a6b448c5-a77e-4769-940f-86ae1d1f3d12", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'2px 3px 1px 3px'}}>

                    <VariableValueExtractor variableId={"fbb182c8-0e53-4207-88be-0a5b67ea7f4a"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink
                        ctaId={ctaId}
                        parentId={parentId} />
                </div>
            </div>

            <div className='flex items-center gap-2'>
                <VariableValueExtractor
                    variableId={"8c292edd-156b-43cf-b06b-1439a87f8423"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />

                <div className="child-container flex my-1" style={{ ...getContainerStyle("50e68307-8e97-4640-acb2-58f3c116f626", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'2px 3px 1px 3px'}}>

                    <VariableValueExtractor variableId={"6dc8cf8a-eae3-406d-82a5-4800a488fa24"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink
                        ctaId={ctaId}
                        parentId={parentId} />
                </div>
            </div>

            <div className='flex items-center gap-2'>
                <VariableValueExtractor
                    variableId={"391b4e71-f116-4210-94f1-77752525b7fd"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />

                <div className="child-container flex my-1" style={{ ...getContainerStyle("abbe1b7a-aae2-40b2-b11d-074c1c3c9be4", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'2px 3px 1px 3px'}}>

                    <VariableValueExtractor variableId={"4743b75b-659a-4748-a39e-ecbea4a3106d"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink
                        ctaId={ctaId}
                        parentId={parentId} />
                </div>
            </div>

            <div className='flex items-center gap-2'>
                <VariableValueExtractor
                    variableId={"853ee5c3-366e-4577-acfc-b1c297b78665"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />

                <div className="child-container flex my-1" style={{ ...getContainerStyle("8053a2ae-40aa-494d-87c9-a3ad8b3e9c23", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'2px 3px 1px 3px'}}>

                    <VariableValueExtractor variableId={"4c8d0ed5-f710-4b89-b6ab-427746bea92c"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink
                        ctaId={ctaId}
                        parentId={parentId} />
                </div>
            </div>

            <div className='flex items-center gap-2'>
                <VariableValueExtractor
                    variableId={"8457b2e5-9913-4f04-a6c2-88f06d57f28c"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />

                <div className="child-container flex my-1" style={{ ...getContainerStyle("09c1c48d-3151-4916-814d-d129016da85a", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'2px 3px 1px 3px'}}>

                    <VariableValueExtractor variableId={"59541b6d-68e3-4586-8862-66307ef8e852"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink
                        ctaId={ctaId}
                        parentId={parentId} />
                </div>
            </div>

            <div className="child-container flex flex-col my-1" style={{ ...getContainerStyle("66252e69-1c24-404d-a948-1e94c080b2d6", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'7px'}}>

                    <VariableValueExtractor variableId={"cb1f9eda-93d9-4b4f-9924-b8ed875af869"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink
                        ctaId={ctaId}
                        parentId={parentId} />

                    <div className="child-container flex my-1" style={{ ...getContainerStyle("e2c3589e-2fa6-434f-8945-54376730eb4b", template, cards, card, variables, templateData, null), flex: "0 0 auto", padding:'5px'}}>
                        <VariableValueExtractor variableId={"5ecb6174-0dcb-4bed-abf3-3e25f2b47308"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink
                            ctaId={ctaId}
                            parentId={parentId} />
                    </div>
                </div>
        </div>
    )
}
export default MakeThe5SkillChecksBelowEachCheckCanBeAttemptedOnlyOnce;