import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from '../utils/VariableValueExtractor';
const TextBookletBlack = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-1 mb-1" style={{width: !fullWidth ? CONTENT_WIDTH : null}}>
            <VariableValueExtractor 
                variableId={"5a065ac8-c027-4422-8de7-d5fbf2f0c2e8"} 
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                ctaId={ctaId}
                parentId={parentId}
                renderId={"bdf20f72-e9e2-4166-8173-0870db3a55e4"}
                />
        </div>
    )
}

export default TextBookletBlack;