import VariableValueExtractor from "../utils/VariableValueExtractor";

const PatrolArrowRight = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    parentId,
    ctaId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0].children[0].children[0];
    let textRender = containerRender.children[0];


    return (
        <div name={`${template._id} - ${template.name}`} className="flex mt-2 justify-center" style={{transform: "rotate(-90deg)", marginLeft:"-7px", marginTop:"-2px"}}>
            <VariableValueExtractor variableId={textRender.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default PatrolArrowRight;