import {CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle, findNestedVariable, getRenderColor} from '../../utils/variable-extractor';
import ClanIcons from '../icons/ClanIcons';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfBothCharacterAreInvolvedInTheSequence = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const x = findNestedVariable("bdb427c8-dd40-47ad-a5a0-135edad176fe", variables)?.value?.content[0].content[0].text;
    const characters = x.split("");

    let containerRender = template.render[0];
    let textRender = containerRender.children[0].children[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }
    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-row justify-between items-center" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            
            <div>
                <VariableValueExtractor variableId={textRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                {findModuleVariables("a9c82cf3-fd90-45ec-94a9-1fd53105a307", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}
                        />
                        )
                    })} 
            </div>
            
            <div className='flex self-start'>
                {characters.map((logo, index) => (
                    index < 2 && (
                        <ClanIcons key={index} name={logo} color={getRenderColor("cdf8ee4a-1e3c-4c44-b65a-cabde1bd5468", template.render, templateData)} />
                    )
                ))}
            </div>

        </div>
    )
}
export default IfBothCharacterAreInvolvedInTheSequence;