import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TextWithBulletPoint = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    
    let containerRender = template.render[0];
    let bulletPointRender = containerRender.children[0]
    let textRender = containerRender.children[1];
    
    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"2px",
        paddingBottom:"2px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-0 flex" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
                <VariableValueExtractor variableId={bulletPointRender?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            <div className='pl-1'>
                <VariableValueExtractor variableId={textRender?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default TextWithBulletPoint;