import VariableValueExtractor from "../utils/VariableValueExtractor";

const FeDirective = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0].children[0];
    let textRender = containerRender.children[0];



    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ width: 'fit-content'  }}>
            <VariableValueExtractor variableId={textRender.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default FeDirective;