import { Link, useNavigate } from "react-router-dom/dist";
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from "../../utils/constants";
import { ReactComponent as BarsIcon } from '../../components/icons/DefaultIconSvgs/Menu.svg'
import { useEffect, useState } from "react";
import MainMenu from "../../components/utils/MainMenu";
import ChapterManager from "../../managers/chapter-manager";
import Sections from "./data/pages.json";
import { Collapse } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export default function FeddingEvents() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [chapters, setChapters] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleMenuClick = () => {
        if (menuOpen === null) {
            setMenuOpen(true);
            return;
        }
        setMenuOpen(!menuOpen);
    }

    useEffect(() => {
        ChapterManager.loadChaptersAsync((e) => {
            if (e === null) setChapters([]);
            else setChapters(e);
            setLoading(false);
        });
    }, [])


    const backgroundImgSrc = BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/Booklets/Feeding%20Event/FE_Separator_Hochelaga_D.webp';
    const topLineImgSrc = BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/Storybook/Graphic_Assets/Ank_Line_Gold.webp';

    return (
        <div
            className="inventory2-page w-full h-full overflow-y-hidden relative flex-col bg-cover bg-center flex justify-center items-center"
            style={{ backgroundImage: `url('${backgroundImgSrc}'` }}
        >
            <div className='background-overlay-faded overflow-y-auto'>
                <div className='w-full items-center flex-col overflow-x-hidden '>
                    {!menuOpen && (<div className='open-close-container'>
                        <a className='open-close-button' href="#1" onClick={() => handleMenuClick()}>
                            <BarsIcon width={50} height={50} fill="white" />
                        </a>
                    </div>)}

                    <div className='mt-20'>
                        <div className='line top'>
                            <img src={topLineImgSrc + "?v=" + V_NUMBER} alt="img" />
                        </div>
                        <h1>FEEDING EVENTS MAP</h1>
                        <p className='description'>
                            Choose any district based on your STATUS level, then choose your prey.
                        </p>
                    </div>
                    <div className='mt-16'>
                        {Sections.map((section, index) => {
                            return (
                                <SectionContainer key={index} section={section} />
                            )
                        })}

                    </div>
                </div>
            </div>
            {menuOpen &&
                (<MainMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} chapters={chapters}></MainMenu>)}
        </div>)
}

function SectionContainer({
    section
}) {
    const navigate = useNavigate();
    const [showAllSubItems, setShowAllSubItems] = useState(false);

    const handleClick = (pageId) => {
        navigate(`/feeding-event/${pageId}`);
    }

    const titleContainerStyle = {
        borderTop: `${DEFAULT_ORANGE_CODE} 1.5px solid`,
        borderBottom: `${DEFAULT_ORANGE_CODE} 1.5px solid`,
        background: 'linear-gradient(90deg, rgba(187,138,67,0.4) 0%, rgba(187,138,67,0) 70%)',
        paddingLeft: "10%",
        paddingRight: "10%"
    }

    const itemContainerStyle = {
        paddingLeft: "15%",
        paddingRight: "15%",
        fontFamily: "Oswald-Bold",
        marginBottom: "20px",
        marginTop: "20px",
    }

    return (
        <>
            <div className="flex items-center justify-between cursor-pointer" style={titleContainerStyle} onClick={() => { setShowAllSubItems(!showAllSubItems) }}>
                <div>
                    <h5 style={{ fontSize: "15px", fontFamily: "CrimsonPro", color: "white" }}>LEVEL {section.Level}</h5>
                    <h3 style={{ fontSize: "25px", textTransform: "uppercase", fontFamily: "Oswald-Bold", color: DEFAULT_ORANGE_CODE }}>{section.name}</h3>
                </div>
                <div>
                    <FontAwesomeIcon icon={!showAllSubItems ? faCaretDown : faCaretUp} size="lg" color={DEFAULT_ORANGE_CODE} />
                </div>
            </div>
            <Collapse open={showAllSubItems}>
                <div className='mb-5 ms-3 underline' style={{ fontSize: "16px" }}>
                    {section.districts.map((page, index) => {
                        return (
                            <div key={index} className='cursor-pointer mb-2 uppercase' style={itemContainerStyle} onClick={() => { handleClick(page.id) }}>{page.name}</div>
                        );
                    })}
                </div>
            </Collapse>
        </>
    )
}