import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import SpacerLine from '../utils/SpacerLine'
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const MakeAAttributeSkillCheckDifficultyXWithRuleGrayBox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }


    let mainContainer = template.render[0]
    let mainText = mainContainer?.children[0]?.children[0]?.uuid
    let childContainer = mainContainer?.children[0]?.children[1]
    let childText = childContainer?.children[0]?.uuid
    let childGreyContainer = mainContainer?.children[1]
     
    const masterContainerStyle = {
        ...getContainerStyle(mainContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(childContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    const childGreyContainerStyle = {
        ...getContainerStyle(childGreyContainer?.uuid, template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            
            <div className="flex items-center">
                <VariableValueExtractor variableId={mainText}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="child-container flex mx-1" style={{ ...childContainerStyle, flex: "0 0 auto", }}>
                    <div className='p-0.5'>
                        <VariableValueExtractor variableId={childText}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>

            <div className="flex items-center flex-wrap mt-2"  style={{ ...childGreyContainerStyle, flex: "0 0 auto", }}>
                <div className="flex items-center">
                    <div className="me-1 ml-2" >
                        <VariableValueExtractor variableId={"d392e9a6-f918-4a73-9899-71f604db5133"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>

            <div className="flex items-center flex-wrap mt-2" >

                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-3px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="P" color='black' width={25} height={25} />
                    </div>

                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"6ebbf75c-4645-4cec-a870-2eca94b1f9c9"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables("5a028a26-20ad-4184-9052-2a022bc81027", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>

                <div className="mx-2">
                    <SpacerLine height={'12px'} />
                </div>

                <div className="flex items-center" style={{ flex: "0 0 auto" }}>
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"f5bd795e-3d9d-4408-930d-73d7d4ae8a64"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables("4a6cdde2-f071-450d-9bb9-ba596239284f", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakeAAttributeSkillCheckDifficultyXWithRuleGrayBox;