import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TemplateTheItemsAreWXYZ = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }


    let containerRender = template.render[0];
    let bulletPointRender = containerRender?.children[0]
    let textRender1 = containerRender?.children[1];
    let textRender2 = containerRender?.children[2];
    let textRender3 = containerRender?.children[3];
    let textRender4 = containerRender?.children[4];
    let ctaIdRender = containerRender?.children[5]?.children[0]?.variable
    
    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"2px",
        paddingBottom:"2px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-0" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <VariableValueExtractor variableId={bulletPointRender?.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <div className='pl-1'>
                <VariableValueExtractor variableId={textRender1?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <VariableValueExtractor variableId={textRender2?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <VariableValueExtractor variableId={textRender3?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <VariableValueExtractor variableId={textRender4?.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className='my-2'>
                {findModuleVariables(ctaIdRender, variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default TemplateTheItemsAreWXYZ;