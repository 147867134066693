import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouWantToLeaveYouCanDoSo = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }    

    const masterContainerStyle = {
        ...getContainerStyle("ee4d2d8b-ff5a-43af-ad07-2dc68c819f2f", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 w-full" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div>
                <VariableValueExtractor variableId={"2cf7c516-fd84-45ba-b3cc-fa4ed83f4d24"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
            </div>


             {findModuleVariables("c3c2e974-5b1e-4a10-a6aa-6edcb22a81af", variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}
                    />
                )
            })} 
        </div>
    )
}

export default IfYouWantToLeaveYouCanDoSo;