import { TailSpin } from "react-loader-spinner"
import { DEFAULT_ORANGE_CODE } from "../utils/constants";

const Loading = () => {
    return (
        <div className="absolute left top w-full h-full flex items-center justify-center">
            <TailSpin
                visible={true}
                height="80"
                width="80"
                color={DEFAULT_ORANGE_CODE}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )
}

export default Loading;