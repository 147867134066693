import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const TemplateMakARouseCheckForEachDifficult = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
    
    let containerRender = template.render[0];
    let textRender = containerRender.children[0];
    let successTextRender = containerRender.children[1].children[0].children[1].uuid;
    let faillureTextRender = containerRender.children[3].children[0].children[1].uuid;
    let line = containerRender?.children[2]?.uuid;


    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(line, template, cards, card, variables, templateData, null),
    
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <VariableValueExtractor variableId={textRender.uuid}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <div className="me-1 flex items-center py-1" style={{ marginTop: "0px", marginLeft: "-5px" }}>
                 <ChaptersIcons name="P" color='black' width={25} height={25} />
                <VariableValueExtractor variableId={successTextRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>

            <div style={{ marginTop: "-0.5rem" }}>
                {findModuleVariables("f2b10b8a-13e1-4b5f-a752-8597142ca217", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>

            <div className='relative line-section w-full justify-end my-2' style={{...childContainerStyle,  height: '1px' }}></div>

            <div className="me-1 flex items-center py-1" style={{ marginTop: "0px", marginLeft: "-5px" }}>
                 <ChaptersIcons name="Q" color='black' width={25} height={25} />
                <VariableValueExtractor variableId={faillureTextRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div style={{ marginTop: "-0.5rem" }}>
                {findModuleVariables("1f73386f-8528-4e02-a321-007a9bf70b38", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default TemplateMakARouseCheckForEachDifficult;