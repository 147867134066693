import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import SpacerLine from '../utils/SpacerLine'
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const MakeAttributeSkillCheckDifficultyX = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("66c52a42-32d9-461c-bcc4-f521d0756dcc", template, cards, card, variables, templateData, null),
        padding: "7px"
    }


    const childContainerStyle = {
        ...getContainerStyle("2c649709-452e-4302-9c63-474766af6ad2", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            
            <div className="flex items-center flex-wrap">
                <div className='mr-1'>
                    <VariableValueExtractor variableId={"7ce551ac-bb90-4ddc-bf79-c58f51a67b79"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                <div className="child-container flex my-1" style={{ ...childContainerStyle, flex: "0 0 auto", }}>
                    <div className='p-0.5'>
                        <VariableValueExtractor variableId={"ae76bd82-77eb-464e-932a-9d683145cb56"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
            </div>


            <div className="flex items-center flex-wrap" >

                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-3px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="P" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"3224aeaa-60ce-4087-a227-b01e594cb83d"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                   {/* -0.45rem */}
                    <div style={{ marginTop: "0rem" }}>
                        {findModuleVariables("5a028a26-20ad-4184-9052-2a022bc81027", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="mx-2">
                    <SpacerLine height={'12px'} />
                </div>
                <div className="flex items-center" style={{ flex: "0 0 auto" }}>
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"76140548-0468-43e7-bf54-5b7f0d87c9ca"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    {/* style={{ marginTop: "0rem" }} */}
                    <div >
                        {findModuleVariables("4a6cdde2-f071-450d-9bb9-ba596239284f", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakeAttributeSkillCheckDifficultyX;