import './_templates.scss'
import { findModuleVariables, getContainerStyle, findNestedVariable, getRenderColor, findNestedModuleVariables } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const EventActionTokenLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("7f902441-72c4-42b4-83db-eb7353f68ebd", template, cards, currentCard, currentCard.variablesValues, templateData, null),
    }

    return (
        // remettre le h-full si jamais sa bug mais sa marche sur chap 17 p35
        <div className="w-full h-full flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div style={{height:"56px", marginTop:"-35px", backgroundColor:masterContainerStyle.backgroundColor}}>
                            <ChaptersIcons name={findNestedVariable("b5c8aef3-dd63-45ef-b9a2-6e9b79439b5c", currentCard.variablesValues)?.value}
                                color={getRenderColor("a97ddc73-1e19-4fae-a2a8-5c8dbe1557e2", template.render, templateData)}
                                width='60px'
                                height='60px'/>
                            </div>

                            <div className='flex' style={{marginBottom:"15px"}}>
                                <VariableValueExtractor variableId={"accd98ca-56f5-4510-9e91-c3430b101507"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null} 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                                <div className='space' style={{ width: '5px' }}></div>
                                <VariableValueExtractor variableId={"9f671b8c-25d2-471a-aef9-d9505e28d350"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>

                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2' >
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>

            <div className='bottom-section' style={{backgroundColor:masterContainerStyle.backgroundColor}}>
                <div className='relative line-section w-full justify-end mt-5' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '1.5px' }}>
                    <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                </div>
                <div className='flex flex-col items-center mt-2'>
                    {findNestedModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard.variablesValues).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default EventActionTokenLeft;