import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveDiscipline1OrDiscipline2LevelX = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender?.children[0]?.children[0]?.uuid;
    let childContainerRender = containerRender?.children[0]?.children[1]?.uuid;
    let childTextRenderBefore = containerRender?.children[0]?.children[1]?.children[0]?.uuid;
    let childLogoRender = containerRender?.children[0]?.children[1]?.children[1]?.uuid;
    let childTextRenderBefore2 = containerRender?.children[0]?.children[3]?.children[0]?.uuid;
    let childLogoRender2 = containerRender?.children[0]?.children[3]?.children[1]?.uuid;
    let childTextRenderAfter = containerRender?.children[0]?.children[2]?.uuid;
    let childModules = containerRender?.children[1]?.variable
    let textLevel = containerRender?.children[0]?.children[4]?.uuid

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        padding: "7px"
    }
    const childContainerStyle = {
        ...getContainerStyle(childContainerRender, template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
       
         <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
             <div className="flex items-center">
                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="child-container flex mx-1 flex items-center " style={{ ...childContainerStyle,flex: "0 0 auto" }}>
                    <VariableValueExtractor variableId={childTextRenderBefore}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className='mt-1'>
                        <VariableValueExtractor variableId={childLogoRender}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
                    <VariableValueExtractor variableId={childTextRenderAfter}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                <div className="child-container flex mx-1 flex items-center " style={{ ...childContainerStyle,flex: "0 0 auto" }}>
                    <VariableValueExtractor variableId={childTextRenderBefore2}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className='mt-1'>
                        <VariableValueExtractor variableId={childLogoRender2}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>
                <VariableValueExtractor variableId={textLevel}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
             </div>

             <div style={{ marginTop: "-0.50rem" }}>
                {findModuleVariables(childModules, variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}/>
                    )
                })}
            </div>
         </div>
    )
}

export default IfYouHaveDiscipline1OrDiscipline2LevelX;