import './_templates.scss'
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, CONTENT_WIDTH, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const InterludeFrontPage = ({
    currentCard,
    template,
    cards,
    templateData,
    deckId,
    ctaId,
}) => {

    const parentId = currentCard._id;

    let backgroundImage = BLOB_URL + template.render[1].image;

    const bottomContainerStyle = {
        ...getContainerStyle("b34ea574-bb58-446c-861b-9883c778aa92", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        padding: "7px",
        width: CONTENT_WIDTH,
        marginTop: "20px"
    }


    return (
        <div className="cover-prologue-front w-full h-full flex flex-col bg-center bg-cover overflow-y-auto items-center py-10"
            style={{ backgroundImage: `url(${backgroundImage})`, paddingTop: "50px", paddingBottom: "20px" }}
        >
            <div className="flex flex-col items-center w-full px-4 text-center force-font-size-25">
                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />
                <VariableValueExtractor variableId={"155b31e2-7094-4c8f-9dcb-dda025d79a8c"}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    variables={currentCard.variablesValues}
                    templateData={templateData}
                    renderId={null}
                    ctaId={ctaId}
                    parentId={parentId} />
                <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />

                <span className='force-font-size text-center' style={{ width: '300px', marginLeft: '30px' }}>
                    {findModuleVariables("f8d62845-74e0-4e63-a979-a24f940cbda3", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                removeLink={true}
                                isLink={false}
                            />
                        )
                    })}
                </span>
            </div>
            <div className='w-full h-full flex flex-col items-center  force-font-size'>
                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                <div style={bottomContainerStyle}>
                    <div className='mb-3'>
                        <VariableValueExtractor variableId={"60024739-1a04-4a28-9ce6-f1459118fa1b"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink={null}
                            ctaId={ctaId}
                            parentId={parentId} />
                    </div>
                    <VariableValueExtractor variableId={"a63538d6-9bc8-4451-9d08-55bcadf69538"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId} />
                    {findModuleVariables("e56dc428-7ed2-4811-bbb3-24b25fdb05fc", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                noStyle={true}
                            />
                        )
                    })}
                </div>

                <div className='text-center mt-5'>
                    {findModuleVariables("f17b4bff-61ea-41e2-abd8-455c6d86ecf8", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                noStyle={true}
                                fullWidth={true}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InterludeFrontPage;