import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfThereAreNoPlayerOnTheTileYouFailBlackbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
   
    let containerRender = template.render[0];
    let textRender = containerRender.children[0].uuid;
    let textRender1 = containerRender.children[1].uuid;
    let textRender2 = containerRender.children[2].uuid;
    let logo = template.variables[0].uuid;
    let colorLogo = containerRender.children[3].children[0].uuid

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex justify-between" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
           <div>
                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                    
                <VariableValueExtractor variableId={textRender1}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={textRender2}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
           </div>
            <div className='mt-2 ' style={{width:'69px', height:'50px', marginTop: '0px'}}>
                <ChaptersIcons  name={findNestedVariable(logo, variables)?.value}
                color={getRenderColor(colorLogo, template.render, templateData)}
                width='80px'
                height='80px'/>
            </div>
        </div>
    )
}
export default IfThereAreNoPlayerOnTheTileYouFailBlackbox;