import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const MakeSkillChecksBelowCanBeAttemptedOnlyOnceSandboxBigBox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender.children[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    const miniBoxContainerStyle = {
        ...getContainerStyle("b1f72416-36d3-4c78-92af-4dc2a019840f", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    const childContainerStyle = {
        ...getContainerStyle("f8b036c3-6408-4eae-b422-9e84579b55d7", template, cards, card, variables, templateData, null),
        padding: '7px'
    }

    const boxColorContainerStyle = {
        ...getContainerStyle("41b71b8d-c837-4f94-b6e9-41844f50302b", template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div>
                <VariableValueExtractor variableId={textRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div>

                <div className='flex my-3 '>
                    <VariableValueExtractor variableId={"f61989e3-488a-4908-9623-6d84dd76c713"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-3 -mt-1" style={{ ...miniBoxContainerStyle, flex: "0 0 auto", }}>
                        <div className='p-0.5'>
                            <VariableValueExtractor variableId={"65fdcce0-a0bd-4bdc-87a5-0b2c7a642fc3"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                </div>

                <div className='flex my-3 '>
                    <VariableValueExtractor variableId={"efcebe6b-abbb-4501-9ae0-436008dd2ca7"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-3 -mt-1" style={{ ...miniBoxContainerStyle, flex: "0 0 auto", }}>
                        <div className='p-0.5'>
                            <VariableValueExtractor variableId={"4305e6d1-eeb8-4c1f-ace4-2a34c21885f2"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                </div>

                <div className='flex my-3 '>
                    <VariableValueExtractor variableId={"5e054958-aadf-4f52-ba71-85c28b417535"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-3 -mt-1" style={{ ...miniBoxContainerStyle, flex: "0 0 auto", }}>
                        <div className='p-0.5'>
                        <VariableValueExtractor variableId={"c7dca9e1-ff9f-46c1-b410-b5c81784ca56"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                        </div>
                    </div>
                </div>

                <div className='flex my-3 '>
                    <VariableValueExtractor variableId={"b0bb9f49-9b06-4183-8a9f-486378f35eeb"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-3 -mt-1" style={{ ...miniBoxContainerStyle, flex: "0 0 auto", }}>
                        <div className='p-0.5'>
                            <VariableValueExtractor variableId={"c85865ee-0f2e-465a-b3e2-bacdacb78ff7"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                </div>

                <div className='flex my-3 '>
                    <VariableValueExtractor variableId={"523ef354-fb87-4236-b28f-12899bee0e3a"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-2 -mt-1" style={{ ...miniBoxContainerStyle, flex: "0 0 auto", }}>
                        <div className='p-0.5'>
                            <VariableValueExtractor variableId={"1bb5cdc2-833f-4fa3-9599-9c3ceaef006d"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="title flex items-center flex-wrap w-full" style={{...childContainerStyle}}>
                <VariableValueExtractor variableId={"5d19fd79-e9bb-46ac-8a2a-4317b5809292"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="mt-3 w-full" style={{ ...boxColorContainerStyle}}>
                    <VariableValueExtractor variableId={"68e61bbf-8280-4816-8da8-2e7497bb29e5"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>       
        </div>
    )
}

export default MakeSkillChecksBelowCanBeAttemptedOnlyOnceSandboxBigBox;