import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveDisciplineLevelXOrTheSkillLevelYSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
   
    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle("47758571-d7d4-4e66-b5b5-bf8027543b0f", template, cards, card, variables, templateData, null),
        padding: '1px'
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            
            <div className="flex items-center">
                <VariableValueExtractor variableId={"a187fccf-816e-4ab0-87d9-0ecdae7c77a1"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <div className="child-container flex mx-1" style={{ ...childContainerStyle, flex: "0 0 auto", }}>
                    <div className='p-0.5'>
                        <VariableValueExtractor variableId={"8cffbee1-c20b-4eb7-9325-e427e60ae44a"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                        <VariableValueExtractor variableId={"413f4f59-936f-496f-bdb5-1fea1d9272cd"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                </div>
                <VariableValueExtractor variableId={"18028c56-3b5b-471c-9509-50e06a987965"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>

            <div className="flex items-center">
                <VariableValueExtractor variableId={"20f497a5-dc0a-4456-8b8f-2a4cabe31e38"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            
            <div>
            {findNestedModuleVariables("5bd40d26-56ef-419a-b165-1e9bca60478e", variables)?.map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        separatorColor={'black'}
                    />
                )
            })}
            </div>
        </div>
    )
}

export default IfYouHaveDisciplineLevelXOrTheSkillLevelYSandbox;