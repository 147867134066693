import './_templates.scss';
import { findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const TableOfContents = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;
    // Table of content title :  "75282f1a-5825-4e84-8a8f-01b2a890d21a"
    // TableOfContentSectionPage CTA :  "6526a49c838075da23c1be9e"

    const backgroundImage = BLOB_URL +  template.render.find(_ => _.name === 'Background Image').image;

    return (
        <div className="w-full h-full flex-col bg-center bg-cover flex justify-center items-center"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="flex flex-col items-center w-full px-4">
                <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />

                <div style={{textAlign:'center'}}>
                    <VariableValueExtractor variableId={"75282f1a-5825-4e84-8a8f-01b2a890d21a"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId}/>               
                </div>

                <img className='mb-5' src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" />

                <div className='w-4/5'>
                {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TableOfContents;