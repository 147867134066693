import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const EventActionTokenEndLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("ead2559c-ad2f-4c33-948e-ea4687653a19", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
                <div className='flex'>
                    <div className='right w-full flex items-center justify-center mt-10'>
                        <div className="flex flex-col items-center w-full px-4">
                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />

                            <div style={{height:"56px", marginTop:"-35px", backgroundColor:"white"}}>
                            <ChaptersIcons name={findNestedVariable("b5c8aef3-dd63-45ef-b9a2-6e9b79439b5c", currentCard.variablesValues)?.value}
                                color={getRenderColor("1768f31b-b94e-48c5-9dcc-6e7d48df93d5", template.render, templateData)}
                                width='60px'
                                height='60px'/>
                            </div>

                            <div className='flex' style={{marginBottom:"15px"}}>
                                <VariableValueExtractor variableId={"90ea4441-7d23-46d3-80c5-c600e295797d"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null} 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                                <div className='space' style={{ width: '5px' }}></div>
                                <VariableValueExtractor variableId={"9652e9ff-13ba-4588-b666-359311ffc8a5"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>

                            <img src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "250px" }} alt="ank-line" />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '3px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("0f20fd15-f6bc-466d-a5db-de8412584635", template, cards, currentCard, currentCard.variablesValues, templateData).image + "?v=" + V_NUMBER
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                            <VariableValueExtractor variableId={"0b6f508c-6d08-4232-b63e-330a2f8cc19d"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink={null} 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Gold.webp"} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"9a3b6d02-eb54-4eeb-96b4-6374ba4f60b0"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EventActionTokenEndLeft;