import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const TemplateStorybookConclusion = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    const masterContainerStyle = {
        ...getContainerStyle("94a6f248-eabc-4724-9f1d-238d484718bd", template, cards, card, variables, templateData, null),
        padding: "0px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-1 flex flex-col full-w" style={{ ...masterContainerStyle}}>
            <div className='flex justify-center items-center'>
                <div className='lineGreyCustom ml-1'>
                    <VariableValueExtractor variableId={"5e0ec0a6-0091-4d6f-8a77-f8bcb32db714"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>       
        </div>
    )
}

export default TemplateStorybookConclusion;