import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables ,findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import SpacerLine from '../utils/SpacerLine'
import VariableValueExtractor from "../utils/VariableValueExtractor";


const RollaRedDieSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {
    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender.children[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    const ctaVariableId = template.render[0].children[1].children[0].variable;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <VariableValueExtractor variableId={textRender.uuid}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
            <div>
            {findNestedModuleVariables(ctaVariableId, variables)?.map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            separatorColor={'black'}
                        />
                    )
                })}
            </div>

            <div className="flex items-center flex-wrap mt-2" >

                <div className="flex items-center">
                    <div className="me-1" style={{ marginTop: "-3px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="P" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"c623cecf-84f0-4b10-9d1a-4eb711c781ac"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables("ce6d1da8-6ac9-408f-ab10-7826df1381f2", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="mx-2">
                    <SpacerLine height={'12px'} />
                </div>
                <div className="flex items-center" style={{ flex: "0 0 auto" }}>
                    <div className="me-1" style={{ marginTop: "-1px", marginLeft: "-5px" }}>
                        <ChaptersIcons name="Q" color='black' width={25} height={25} />
                    </div>
                    <div className="me-1" style={{ marginLeft: "-7px" }}>
                        <VariableValueExtractor variableId={"64f47c25-05cf-4d98-b295-a0a2c0429e7f"}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div style={{ marginTop: "-0.45rem" }}>
                        {findModuleVariables("15ef6470-aaf4-4b7e-9692-5fdaba0f3ff5", variables).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={card}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    fullWidth={true}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RollaRedDieSandbox;