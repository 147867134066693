import { CONTENT_WIDTH} from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouWinLoseSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("0390d534-a707-402c-a76a-6175566c0320", template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center">
                <VariableValueExtractor variableId={"71d0a2a5-5e1a-4454-99c5-acaaacc49ca3"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>

            <div>
                {findModuleVariables("c3c2e974-5b1e-4a10-a6aa-6edcb22a81af", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouWinLoseSandbox;