import {  DEFAULT_ORANGE_CODE } from '../../utils/constants';
import { findNestedVariable} from '../../utils/variable-extractor';

const FeedingEventPhysicalOrMentalResult = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    isPhysical
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const borderValue = `2px ${DEFAULT_ORANGE_CODE} solid`;
    const allColStyle = {
        padding:"10px",
        textAlign:'center',
        fontFamily:"Oswald-Bold"
    }

    const leftColStyle = {
        borderTop: borderValue,
        borderRight: borderValue,
        borderBottom: borderValue,
        ...allColStyle
    }

    const rightColStyle = {
        borderTop: borderValue,
        borderLeft: borderValue,
        borderBottom: borderValue,
        ...allColStyle
    }

    const colStyle = {
        border: borderValue,
        ...allColStyle
    }

    var physical1 = findNestedVariable("3befd495-2da5-4389-8a9b-8c151d8d3857", variables)?.value;
    var physical2 = findNestedVariable("17589821-99c8-468a-a3d8-ecadc3680708", variables)?.value;
    var physical3 = findNestedVariable("7c43ace0-1912-49df-b51c-b87f54797ef1", variables)?.value;
    var physical4 = findNestedVariable("6242a4f3-1e52-4be4-8d50-8773a38e3cc7", variables)?.value;

    var mental1 = findNestedVariable("b4b834c3-ebe2-4001-a586-077ad95f5e71", variables)?.value;
    var mental2 = findNestedVariable("b1c10165-c16f-42bc-9d68-b2beba6ba9f6", variables)?.value;
    var mental3 = findNestedVariable("36bd10b1-3245-4ad8-85a4-12b42f0ca6a6", variables)?.value;
    var mental4 = findNestedVariable("5e8805cf-e8cd-4896-adcc-d50df4f67b2b", variables)?.value;

    var value1 = isPhysical ? physical1 : mental1;
    var value2 = isPhysical ? physical2 : mental2;
    var value3 = isPhysical ? physical3 : mental3;
    var value4 = isPhysical ? physical4 : mental4;

    return (
        <table className='w-full'>
            <thead style={{ background: 'linear-gradient(90deg, rgba(187,138,67,0.4) 0%, rgba(187,138,67,0) 70%)', color: DEFAULT_ORANGE_CODE }}>
                <tr>
                <th style={leftColStyle}>1P</th>
                <th style={colStyle}>2P</th>
                <th style={colStyle}>3P</th>
                <th style={rightColStyle}>4P</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={leftColStyle}>{value1 ? value1: 0}</td>
                    <td style={colStyle}>{value2 ? value2: 0}</td>
                    <td style={colStyle}>{value3 ? value3: 0}</td>
                    <td style={rightColStyle}>{value4 ? value4: 0}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default FeedingEventPhysicalOrMentalResult;