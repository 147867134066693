import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouHaveDisciplineLevelXSandboxV3 = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {



    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("98ba512d-9ce2-4eef-bf1e-99eae96a14b6", template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle("45808ee8-fef9-43cf-aeb0-ded3939a4c58", template, cards, card, variables, templateData, null),
        padding:'1px',
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap">
                <div className="flex items-center flex-nowrap text-nowrap">
                    <VariableValueExtractor variableId={"60695f9a-3da0-4d39-8f0b-531141f87294"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-1" style={childContainerStyle}>
                        <div className='p-0.5 flex'>
                            <VariableValueExtractor variableId={"846beb9d-45ba-4176-bd16-cb47c7eb7553"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null} 
                                ctaId={ctaId}
                                parentId={parentId}/>

                            <VariableValueExtractor variableId={"938ef0ba-3a05-488a-a098-eb9b17f96056"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </div>
                    </div>
                    <VariableValueExtractor variableId={"0c7baa89-b8fe-46c3-83be-ff7c5835b4c2"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
                    <VariableValueExtractor variableId={"7cd346de-a6dc-44ef-89d3-90fab967f25a"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                <div className="flex items-center flex-nowrap text-nowrap">
                    <VariableValueExtractor variableId={"f56c8cc4-820c-49e6-b864-032aeb424fc9"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <div>
                {findModuleVariables("6011bfcf-c0fb-4e8e-8293-926b955cbc12", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouHaveDisciplineLevelXSandboxV3;