import { CONTENT_WIDTH } from '../../utils/constants';
import { findNestedVariable, getContainerStyle, getRenderColor } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouLoseYouFailBlackboxMultitext = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {


    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("42201504-4582-44ae-9284-a2a5880ffa9c", template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex"style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="grow">

                <VariableValueExtractor variableId={"ee3accaf-f571-4e02-9fd0-0d70ee4a8e2f"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={"d2960dd0-9d87-4622-8fb0-8df8d29b210c"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={"de9de671-95e5-457f-8504-bc83c70beceb"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className='flex items-center'>
            <div className='mt-2' style={{width:'69px', height:'50px', marginTop: '-26px'}}>
                    <ChaptersIcons name={findNestedVariable("d724939e-cc12-45a1-8209-4696f0c177eb", variables)?.value}
                    color={getRenderColor("c147dbd4-93cb-44db-a078-a9dbb9398c7f", template.render, templateData)}
                    width='80px'
                    height='80px'/>
                </div>
            </div>
        </div>
    )
}

export default IfYouLoseYouFailBlackboxMultitext;