import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const MakeAttributeSkillCheckBlackbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const masterContainerStyle = {
        ...getContainerStyle("bd1d9c76-bb12-41b3-8168-fb8d64ce1ce2", template, cards, card, variables, templateData, null),
        padding: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 text-center w-full" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="text-center">
                <VariableValueExtractor variableId={"d6289021-956c-4b51-a47d-4e30adcd2d23"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>

            {findModuleVariables("987f4d9f-b349-4b7a-bfdd-13013afd620d", variables).map((variable, index) => {
                return (
                    <ModuleSelector
                        key={index}
                        variable={variable}
                        card={card}
                        cards={cards}
                        deckId={deckId}
                        templateData={templateData}
                        fullWidth={true}
                    />
                )
            })}
        </div>
    )
}

export default MakeAttributeSkillCheckBlackbox;