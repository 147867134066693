import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle, findNestedModuleVariables, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import ClanIcons from '../icons/ClanIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const StorybookIntroTextCalebLetter = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    
    
    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template?.render[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }


    const ctaVariableId = template.render[0]?.children[1]?.variable;

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex " style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className='grow'>
                <VariableValueExtractor variableId={ctaVariableId}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/> 
            </div>
        </div>
    )
}

export default StorybookIntroTextCalebLetter;