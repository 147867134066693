import { CONTENT_WIDTH } from '../../utils/constants';
import { findNestedVariable, getContainerStyle, getRenderColor } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouHaveClueTokenXAndActionTokenXIsNotOnTheTileChapterFailBlackbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    var container = template.render[0];
    var text1 = container.children[0].children[0]
    var text2 = container.children[0].children[1]
    var text3 = container.children[0].children[2]
    var text4 = container.children[0].children[3]

    const masterContainerStyle = {
        ...getContainerStyle(container.uuid, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex"style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="grow">

                <VariableValueExtractor variableId={text1.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={text2.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={text3.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={text4.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                
            </div>
            <div className='flex items-center'>
            <div className='mt-2' style={{width:'69px', height:'50px', marginTop: '-26px'}}>
                <ChaptersIcons name={"L"}
                    color={'rgb(167, 32, 36)'}
                    width='80px'
                    height='80px'/>
                </div>
            </div>
        </div>
    )
}

export default IfYouHaveClueTokenXAndActionTokenXIsNotOnTheTileChapterFailBlackbox;