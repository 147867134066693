import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE,  BLOB_URL, V_NUMBER } from '../../utils/constants';
import { FONT_SIZE_MP } from '../../utils/libs';
import { Link } from 'react-router-dom';
import HexGrid from '../tools/HexGrid';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const ExpansionPackIntro1NPCColumnPatrolSpecialInstruction = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    eventPage,
    ctaId,
}) => {

   const parentId = currentCard._id;
  
   const masterContainerStyle = {
        ...getContainerStyle("309721ec-23a3-45f3-bf7c-5eb07dd37ca8", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto" style={masterContainerStyle}>


            <div className='w-full text-center' style={{ marginTop: "50px" }}>
                <h5 style={{ fontFamily: "CrimsonPro", color: DEFAULT_ORANGE_CODE, fontWeight: "bold", fontSize: `${8 * FONT_SIZE_MP}px` }}>
                    <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </h5>
                <h1 style={{ fontFamily: "Oswald-Bold", color: "Black", fontWeight: "bold", fontSize: `${20 * FONT_SIZE_MP}px` }}>
                    INTRODUCTION
                </h1>
            </div>

            <div className='force-font-size flex flex-col items-center mt-3'
                style={{ fontFamily: "CrimsoPro", color: "black" }}>
                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
            </div>

            <div className='flex justify-center my-5'>
                <div style={{
                    width: CONTENT_WIDTH,
                    ...getContainerStyle("17180144-35c6-492f-9e18-b5306ab9b7d0", template, cards, currentCard, currentCard.variablesValues, templateData),
                    padding: '15px'
                }}>
                    <h3 style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "CrimsonPro" }}>
                        OBJECTIVE
                    </h3>

                    <div style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                        <VariableValueExtractor variableId={"3b0596d3-eb6f-47fe-a021-f1b9d16402e7"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div className='line my-4'></div>

                    <h3 style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "CrimsonPro", }}>
                        ACTION
                    </h3>
                    <div style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                        <Link to={`/${deckId}/${eventPage}`}>
                            <VariableValueExtractor variableId={"0fcc37f2-6788-41ed-a982-aa3903e08f35"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={"1360f240-4ec2-4bc0-a9dc-f0a170711269"}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </Link>
                    </div>
                    <div className='line my-4'></div>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center my-5'>
                <div style={{ width: '175px' }}>
                    <div className='line my-2' style={{ height: '2px' }}></div>
                    <h3 className='text-center' style={{ fontFamily: "Oswald-Regular", fontSize: '25px' }}>INITIAL TILE <span className='orange-text ftOswaldSb'>SETUP</span></h3>
                    <div className='line my-2' style={{ height: '2px' }}></div>
                </div>
            </div>

            <div className='flex justify-center mb-5'>
                <HexGrid
                    variables={currentCard.variablesValues}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    templateData={templateData}
                    variable="633b2f6d-3791-47f3-80d8-76f91974c795"
                />
            </div>  
            
            <div className='flex flex-wrap justify-center mb-5'>
                {findModuleVariables("76bf0b33-0d80-4042-bfa1-415265b8b776", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
            </div>

             <div className='flex justify-center'>
                <div className='flex flex-col justify-center mb-5 text-center justify-center p-2 force-font-size' style={{ width: CONTENT_WIDTH, backgroundColor:"#f1eae0" }}>
                    <VariableValueExtractor variableId={"3eb8e9c7-f1c0-46ae-b8a4-399ea8bc5aa7"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={"c8aef57b-8ca7-4f32-ac93-2b722e8b7c99"}
                        isLink={null}
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>  
        </div>
    )
}

export default ExpansionPackIntro1NPCColumnPatrolSpecialInstruction;