import './_templates.scss'
import { findModuleVariables, findVariableOrDefaultToTempateValue, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE, V_NUMBER} from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import { FONT_SIZE_MP } from '../../utils/libs';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const ChapterConclusion = ({
    currentCard,
    template,
    variables,
    card,
    cards,
    templateData,
    deckId,
    ctaId
}) => {

    const parentId = currentCard._id;
    
    let backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("79ab1bb6-fef2-4bca-a384-fb23bc33ace9", template, cards, currentCard, currentCard.variablesValues, templateData)?.value;

    const bottomContainerStyle = {
        ...getContainerStyle("3e6aa12f-1e36-4728-91b6-7d72a8de69d7", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        padding: "7px",
        width: CONTENT_WIDTH,
        marginTop: "20px"
    }

    return (
        <div className="cover-prologue-front w-full h-full flex flex-col bg-center bg-cover overflow-y-auto force-font-size items-center py-10"
            style={{ backgroundImage: `url(${backgroundImage})`, paddingTop:"50px", paddingBottom:"20px", backgroundColor:"rgb(35, 31, 32)" }}
        >
            <div className=" flex flex-col grow items-center">
                <div className='w-full text-center mb-2'>
                    <h5 style={{ fontFamily: "CrimsonPro", color: DEFAULT_ORANGE_CODE, fontWeight: "bold", fontSize: `${8 * FONT_SIZE_MP}px` }}>
                        <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </h5>
                    <h1  style={{ fontFamily: "Oswald-Bold", color: "#f1e4d7", fontWeight: "bold", fontSize: `${20 * FONT_SIZE_MP}px` }}>
                        CONCLUSION
                    </h1>
                </div>

                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
                
                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                <div style={bottomContainerStyle}>
                    <div className={"mb-2"}>
                        <VariableValueExtractor variableId={"0f7bb484-baea-4e3a-b780-93374a4d3042"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <div>
                        {findModuleVariables("e56dc428-7ed2-4811-bbb3-24b25fdb05fc", currentCard).map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={currentCard}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    noStyle={true}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
                
            <div className='mt-5' style={{width: "90%"}}>
                <div className='relative line-section w-full justify-end fade-to-fade-gold' style={{ height: '1.5px' }}>
                    <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.7rem' }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                </div>

                <div style={{textAlign:"center"}}>
                    {findModuleVariables("f17b4bff-61ea-41e2-abd8-455c6d86ecf8", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                noStyle={true}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ChapterConclusion;