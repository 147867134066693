import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const ConclusionTextBlock = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let containerRender = template.render[0];
    let textRender = containerRender?.children[0];
    let textRender2 = containerRender?.children[0]?.uuid

    const masterContainerStyle = {
        ...getContainerStyle(containerRender?.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px",
        
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: "100%"}}>
            <VariableValueExtractor variableId={"b7d377b5-31be-49fc-a39f-72bd31e4fadf"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={textRender?.uuid}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <VariableValueExtractor variableId={"3a900d75-b530-4d2d-a8d6-f9af74f0abc3"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={textRender2}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default ConclusionTextBlock;