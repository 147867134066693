import { CONTENT_WIDTH } from '../../utils/constants';
import { getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const GangrelRuleBoxWithTable = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    
    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    var topContainer = template.render[0].uuid;

    const containerStyle = {
        ...getContainerStyle(topContainer, template, cards, card, variables, templateData, null),
        width: !fullWidth ? CONTENT_WIDTH : null,
        padding: "7px"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={containerStyle}>
            <VariableValueExtractor variableId={"81674d52-5426-4e3b-8f81-e4752cab1533"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <table className="tg w-full" >
                <thead>
                    <tr>
                        <th className="tg-0lax">
                            <VariableValueExtractor variableId={"4c999425-03c5-4061-a916-31bccd8fe4b8"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </th>
                        <th className="tg-0lax">
                            <VariableValueExtractor variableId={"8e1435a5-5727-40d8-bf25-33cae78b793e"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </th>
                        <th className="tg-0lax">
                            <VariableValueExtractor variableId={"f1682e78-1e07-4080-a385-44f6ecfc6c88"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </th>
                        <th className="tg-0lax">
                            <VariableValueExtractor variableId={"22f38cee-6cc8-4e20-9553-f28323b60e39"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="tg-0lax text-center" colSpan="4">
                            <VariableValueExtractor variableId={"ff8247be-dd9d-4596-83a8-db0ae455e408"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                       </td>
                    </tr>
                    <tr>
                        <td className="tg-0lax content right-border align-top">
                            <VariableValueExtractor variableId={"d707d696-0b83-408a-aae3-1feda5411d85"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content right-border align-top">
                            <VariableValueExtractor variableId={"b07baf36-8810-41c9-8722-c6a0a180d34f"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content right-border align-top">
                            <VariableValueExtractor variableId={"d71e307f-6ad5-49fc-8d52-3d6524616492"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content align-top">
                            <VariableValueExtractor variableId={"79bb300c-0bc8-49ea-8c00-f7af74b12c58"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            </td>
                    </tr>
                    <tr>
                        <td className="tg-0lax text-center" colSpan="4">
                            <VariableValueExtractor variableId={"35a2a2f8-f915-4cf7-9b7f-cf9736bc1ec8"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            
                        </td>
                    </tr>
                    <tr>
                        <td className="tg-0lax content right-border align-top">
                            <VariableValueExtractor variableId={"3941b24b-5281-449c-bd4c-ed55328a23f4"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content right-border align-top">
                            <VariableValueExtractor variableId={"86b0b969-b91f-470f-b3dc-bcb82ba72a15"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                            
                        </td>
                        <td className="tg-0lax content right-border text-center">
                            <VariableValueExtractor variableId={"29bb5364-e043-4bf0-acbc-86a9de965f2e"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content text-center">
                            <VariableValueExtractor variableId={"adf714e1-bb9e-4aa0-82bf-d60d5a9e30ef"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="tg-0lax text-center" colSpan="4">
                            <VariableValueExtractor variableId={"07425ff5-063c-4d23-95cf-9dcbda6a384b"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="tg-0lax content right-border text-center">
                            <VariableValueExtractor variableId={"05dc6a4b-fc2d-404d-b5e8-b46f6068898e"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content right-border text-center">
                            <VariableValueExtractor variableId={"a4b5a473-2678-43f9-b104-c7f075b15560"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content right-border align-top">
                            <VariableValueExtractor variableId={"28f58be0-f43b-426f-9336-890b9e9fab68"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                        <td className="tg-0lax content text-center">
                            <VariableValueExtractor variableId={"bbf0d370-b2c0-4ab8-9cc0-1c9fdca9a765"}
                                template={template}
                                cards={cards}
                                card={card}
                                variables={variables}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default GangrelRuleBoxWithTable;