import { useNavigate } from "react-router-dom";
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TableOfContentsItemPage = ({
    variables,
    template,
    cards,
    card,
    deckId,
    templateData,
    ctaId,
    parentId
}) =>{

    // Section Name: "73a1b176-a04b-4188-8a2f-31be7bcfed34"
    // Page Number: "ed9e22d3-c353-4462-b1e7-5a43fdb98258" -> page id => 65158e6c838075da23be0d15

    const navigate = useNavigate();


    var variable = findVariableOrDefaultToTempateValue("e6c2a780-7f54-4176-9ea0-c6e56ae01d65", template, cards, card, variables, templateData);

    const handlePageClick = () => {
        navigate(`/${deckId}/${variable.value}`);
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="flex w-full items-end cursor-pointer mb-1" style={{color:'white', fontSize:"20px"}} onClick={() => {handlePageClick()}}>
            <div className='' style={{flex:"0 0 auto"}}>
                <VariableValueExtractor variableId={"ad78a278-6007-4136-acdb-5bacf5d08e3d"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className="w-full items-end mb-1.5" style={{height:"1px", backgroundColor:'white'}}></div>
            <div className='break-keep'>
                <VariableValueExtractor variableId={"9cd6a237-745d-48b1-9e16-6ae8d93c1be5"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default TableOfContentsItemPage;