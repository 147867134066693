import './_templates.scss'
import { findVariableOrDefaultToTempateValue } from '../../utils/variable-extractor';
import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const DialogueNPCDivider = ({
    currentCard,
    template,
    cards, 
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const backgroundImage = BLOB_URL +  findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48",  template, cards, currentCard, currentCard.variablesValues, templateData).value;
    
    return (
        <div className="cover-prologue-front w-full h-full flex-col bg-center bg-cover flex justify-center items-center"
            style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
            <div className='grow'></div>
            <div className="flex flex-col items-center w-full px-4 text-center mb-5" style={{height:"150px"}}>
                <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-1"/>
                <VariableValueExtractor variableId={"9d2d7090-65f2-4771-b8e6-f4aad659aff0"}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    variables={currentCard.variablesValues}
                    templateData={templateData}
                    renderId={"8cbbcb6b-13bf-4f8c-8e13-55a65466f542"}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Line+Tips.webp" + "?v=" + V_NUMBER} style={{ maxWidth: "300px" }} alt="ank-line" key="img-2"/>
            </div>
        </div>
    )
}

export default DialogueNPCDivider;