import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, DEFAULT_ORANGE_CODE, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const DialogueEndLeft = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;


    const masterContainerStyle = {
        ...getContainerStyle("fe369ffe-4429-4961-86cd-05e0243cb4e6", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    const topLeftImage = findVariableOrDefaultToTempateValue("d6afd089-baab-4964-af31-d475b4d1fa48", template, cards, currentCard, currentCard.variablesValues, templateData);

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
                <div className='header flex'>
                    <div className='flex background-image' style={{width:"100px", height:"100px", backgroundImage:`url(${BLOB_URL}${topLeftImage.value})`}}>
                        
                    </div>
                    <div className='flex flex-col justify-between w-full'>
                        <div></div>
                        <div className='flex flex-col ms-10'>
                            <div className='flex'>
                                <VariableValueExtractor variableId={"6194c1f1-5cf4-4941-b5bc-35f13a2f1db8"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null} 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            
                                <VariableValueExtractor variableId={"9d2d7090-65f2-4771-b8e6-f4aad659aff0"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={"6194c1f1-5cf4-4941-b5bc-35f13a2f1db8"}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>
                            <div>
                                <VariableValueExtractor variableId={"eb8e1673-6d51-4aac-8a07-bbe4b0cd7861"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={null}
                                    parentId={parentId}/>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                        <div className='full-to-fade-gradient' style={{height:"2px", width:"100%"}}></div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>
            <div className='relative line-section w-full justify-end mt-10' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '3px' }}>
                <img className='absolute w-2' style={{ left: 'calc(50% - 4px)', top: '-0.6rem' }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{height:'200px',  backgroundImage:`url(${BLOB_URL}${
                    findVariableOrDefaultToTempateValue("81ed4ab6-4cd1-4d9d-9cb6-f44e9b6acd3f",  template, cards, currentCard, currentCard.variablesValues, templateData)?.image
                })`}}>
                    <div  className='background-overlay flex flex-col items-center justify-center'>
                        <div>
                            <VariableValueExtractor variableId={"fc8b700c-55ed-4638-addc-3f0d5214ab19"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink ={null}
                                ctaId={null}
                                parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: DEFAULT_ORANGE_CODE, height: '2px'}}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width:"15px" }} src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Gold.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                            <VariableValueExtractor variableId={"727480d1-79cd-4aff-aaa1-148cfda01650"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={null}
                                isLink 
                                ctaId={null}
                                parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DialogueEndLeft;