import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import { findNestedVariable} from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const SetupNpcTokenV2 = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"


    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    let textRender = template?.render[0]?.children[0]?.children[1]?.uuid
    var image = findNestedVariable("7e1ea13b-d5b0-4bc3-a529-7fc71145dd43", variables);

    var token = templateData.tokens.find(_=>_._id === image.value);
    var url = token.image;

    return (
        <div name={`${template._id} - ${template.name}`} className="flex flex-wrap  mx-2" style={{minWidth:"100px", justifyContent: "center"}}>
            <div className='flex flex-col text-center items-center' style={{width:"fit-content", fontFamily:"CrimsonPro-Bold"}}>
                <img src={BLOB_URL + url + "?v=" + V_NUMBER} width={100} alt="token"/>
                <VariableValueExtractor variableId={"eee26fa8-fb7b-4a0a-baf0-7faa2ce4fbb4"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={textRender}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={"4cf569ac-b4b7-40bb-86e7-f9e7599ac45a"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink={false}
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default SetupNpcTokenV2;