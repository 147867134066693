import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import { BLOB_URL, CONTENT_WIDTH, V_NUMBER } from '../../utils/constants';
import ModuleSelector from '../modules/_module-selector';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const InvestigationFail = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    ctaId,
}) => {

    const parentId = currentCard._id;

    const masterContainerStyle = {
        ...getContainerStyle("31a76dc8-fabd-464b-a0a2-fcc0063e63aa", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        backgroundImage:`url(${BLOB_URL}${findVariableOrDefaultToTempateValue("0d84f345-4d39-4233-adaa-1b7a717b2af5", template, cards, currentCard, currentCard.variablesValues, templateData)?.image}`
    
    }

    return (
        <div className="w-full h-full flex flex-col" style={masterContainerStyle}>
            <div className='top-section flex flex-col grow'>
          
                <div className='header flex'>
                    <div className='flex flex-col justify-between items-center w-full'>
                        <div className='flex flex-col my-5' style={{width: CONTENT_WIDTH}}>
                            <div className='flex'>
                                <VariableValueExtractor variableId={"df55c5bf-587d-4a35-bc36-08f4226fa28a"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink={null}
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            <div className='ml-1' style={{color: 'black', fontSize: '18px', fontFamily: 'Oswald-SemiBold', fontWeight: '400', textTransform: 'uppercase'}}>
                                <VariableValueExtractor variableId={"0f2cca82-7eb5-423b-b0eb-eb3182202aa5"}
                                    template={template}
                                    cards={cards}
                                    card={currentCard}
                                    variables={currentCard.variablesValues}
                                    templateData={templateData}
                                    renderId={null}
                                    isLink 
                                    ctaId={ctaId}
                                    parentId={parentId}/>
                            </div>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                        <div className='full-to-fade-gradient' style={{height:"2px", width:"100%"}}></div>
                    </div>
                </div>

                <div className='flex items-center justify-center flex-col mt-2'>
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
            </div>

            <div className='relative line-section w-full justify-end mt-10 ' style={{ backgroundColor: "rgb(167, 32, 36)", height: '3px'}}>
                <div className='absolute w-2' style={{ left: 'calc(50% + -38px)', top: '-30px' }} >
                    <ChaptersIcons name="L"
                    color='rgb(167, 32, 36)'
                    width='80px'
                    height='80px'/>
                </div>
            </div>

            <div className='bottom-section'>
                <div className='flex flex-col items-center justify-center background-image' style={{
                    height: '200px', backgroundImage: `url(${BLOB_URL}${findVariableOrDefaultToTempateValue("80416e34-cc7c-4ac7-b34b-85738cea9c8f", template, cards, currentCard, currentCard.variablesValues, templateData)?.image
                        })`
                }}>
                    <div className='background-overlay flex flex-col items-center justify-center text-center'>
                        <div>
                        <VariableValueExtractor variableId={"b36e8cec-6e88-4bc0-99c3-29fe16a54860"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                            <div className='relative line-section w-full justify-end mt-1 mb-3' style={{ backgroundColor: "rgb(167, 32, 36)", height: '2px' }}>
                                <img className='absolute w-2' style={{ left: 'calc(50% - 7.5px)', top: 'rem', width: "15px" }} src={BLOB_URL + "upload/images/64ed011e17b7622c4d323360/Booklets/icons/Tip_Line_Red.webp" + "?v=" + V_NUMBER} alt="ank" />
                            </div>
                        </div>
                        <VariableValueExtractor variableId={"4b37d264-0ce8-41f0-a5b1-8072e9b760f1"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InvestigationFail;