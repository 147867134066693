import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TemplateOtherwiseResumePlayingBlackText = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-2" style={{ width: !fullWidth ? CONTENT_WIDTH : null, flex: '0 0 auto' }}>
            <VariableValueExtractor variableId={"e09fbec5-f576-4027-9972-cef16c8ac4f8"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <VariableValueExtractor variableId={"a288709f-5ce0-4337-a509-92c32f8ee443"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default TemplateOtherwiseResumePlayingBlackText;