import { CONTENT_WIDTH} from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";


const OtherwiseResumePlayingCreamText = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {
    
    return (
        <div name={`${template._id} - ${template.name}`} className="my-2" style={{ width: !fullWidth ? CONTENT_WIDTH : null, flex: '0 0 auto' }}>
            <VariableValueExtractor variableId={"7d1f1cc2-070f-4795-b0dc-940b91fa3661"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink 
                ctaId={ctaId}
                parentId={parentId}/>
            <VariableValueExtractor variableId={"18bd1f65-7a9b-485f-86e1-5386d77c2b4d"}
                template={template}
                cards={cards}
                card={card}
                variables={variables}
                templateData={templateData}
                renderId={null}
                isLink={null} 
                ctaId={ctaId}
                parentId={parentId}/>
        </div>
    )
}

export default OtherwiseResumePlayingCreamText;