import { getContainerStyle, findModuleVariables } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import { CONTENT_WIDTH } from '../../utils/constants';


const TemplateTextGeneral = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
    
    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-col text-center" style={{  width: !fullWidth ? CONTENT_WIDTH : null }}>
            <VariableValueExtractor variableId={"3ebbe561-f38b-4464-a7a8-5728695cd2fe"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/> 
        </div>
    )
}

export default TemplateTextGeneral;