import ChapterManager from "../managers/chapter-manager";
import { createContext, useEffect, useState } from "react";


const TranslationsContext = createContext({
    translationsCards:[],
});

export const TranslationsContextProvider = ({children}) => {
    const [translationsCards, setTranslationsCards] = useState([]);
    const [translationsCtas, setTranslationsCtas] = useState([]);
    const [translationsEnums, setTranslationsEnums] = useState([]);
    const [translationLoading, setTranslationLoading] = useState(true);

    useEffect(() => {
        ChapterManager.loadTranslationsCardsAsync((r) => {
            setTranslationsCards(r);
            setTranslationLoading(false);
        });

        ChapterManager.loadTranslationsCtasAsync((r) => {
            setTranslationsCtas(r);
            setTranslationLoading(false);
            // console.log('translationsCtas', r);
        });

        ChapterManager.loadTranslationsEnumsAsync((r) => {
            setTranslationsEnums(r);
            setTranslationLoading(false);
            // console.log('translationsEnums', r);
        });
    }, []);
    

    

    const context = {translationsCards, translationsCtas, translationsEnums, translationLoading }
    return (
        <TranslationsContext.Provider value={context}>
            {children}
        </TranslationsContext.Provider>
    )
}

export default TranslationsContext;