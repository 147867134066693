import { CONTENT_WIDTH } from '../../utils/constants';
import { findNestedVariable , getRenderColor, getContainerStyle } from '../../utils/variable-extractor';
import ChaptersIcons from '../icons/ChaptersIcons';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfYouAreTheOnlyPlayerOnTheTileYouFailBlackboxMultitext = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    const masterContainerStyle = {
        ...getContainerStyle("353caf82-b0c8-4526-a424-d2cb4ea6aba3", template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex justify-between" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div>
                <VariableValueExtractor variableId={"f31d5333-b666-4d03-8442-244d70dfcc78"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
                    
                <VariableValueExtractor variableId={"d6f1f702-ed82-4d1b-9c75-2af971a0d27d"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={"fd25d238-dd70-4254-ba26-7bcb48a7b6e1"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div className='mt-2 items-start' style={{width:'69px', height:'50px', marginTop:"0px"}}>
                <ChaptersIcons  name={findNestedVariable("d724939e-cc12-45a1-8209-4696f0c177eb", variables)?.value}
                color={getRenderColor("8aeaed21-444c-45d3-b7c1-c95493578adb", template.render, templateData)}
                width='80px'
                height='80px'/>
            </div>
        </div>
    )
}

export default IfYouAreTheOnlyPlayerOnTheTileYouFailBlackboxMultitext;