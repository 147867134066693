import { V_NUMBER } from "../../utils/constants";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const TemplateSetupPreyNpcSectionV2 = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"

    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }

    let textRender = template.render[0].children[2].uuid;

    return (
         <div name={`${template._id} - ${template.name}`} className="flex flex-wrap w-full justify-center px-2">
            <div className='flex flex-col justify-center items-center my-1'>
                <div style={{ width: '175px' }}>

                    <div className='flex flex-col justify-center items-center'>
                        <div style={{ width: '175px' }}>
                            <div className='line my-2' style={{ height: '2px' }}></div>
                            <h3 className='text-center' style={{ fontFamily: "Oswald-Regular", fontSize: '25px' }}>
                                <span className="customTitleNpc">
                                    <VariableValueExtractor variableId={textRender}
                                        template={template}
                                        cards={cards}
                                        card={card}
                                        variables={variables}
                                        templateData={templateData}
                                        renderId={null}
                                        ctaId={ctaId}
                                        parentId={parentId}/>
                                </span>
                            </h3>
                            <div className='line my-2' style={{ height: '2px', position:"relative" }}>
                                <div style={{position:"absolute", width:"40px", backgroundColor:"white", left:"calc(50% - 20px)", top:"-10px"}} className='flex justify-center'>
                                    <img src={'/images/common/dialogue.webp'+ "?v=" + V_NUMBER} alt='dialog'  width={'30px'}/>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div> 
      
    )
}

export default TemplateSetupPreyNpcSectionV2;