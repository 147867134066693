import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import { getContainerStyle } from '../../utils/variable-extractor';

const TextBoxDoubleContainerContainerContainer = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }


    let containerRender = template.render[0].children[0];
    let textRender = containerRender.children[0];
    let text2Render = containerRender.children[1];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="w-full force-title-font-size text-center">
                <VariableValueExtractor variableId={textRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />

                <VariableValueExtractor variableId={text2Render.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink
                    ctaId={ctaId}
                    parentId={parentId} />
            </div>
        </div>
    )
}

export default TextBoxDoubleContainerContainerContainer;