import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom/dist";
import ChapterManager from "../../managers/chapter-manager";
import { useEffect, useState } from "react";
import { orderCards } from "../../utils/libs";
import Loading from "../../components/Loading";
import FeedingEventSeparator from "./components/FeedingEventSeparator";
import FeedingEventPage from "./components/FeedingEventPage";
import { ReactComponent as BarsIcon } from '../../components/icons/DefaultIconSvgs/Menu.svg'
import MainMenu from "../../components/utils/MainMenu";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Sections from "./data/pages.json";
import SelectionResultPage from "./components/SelectionResultPage";
import { findModuleVariables, findRenderObjectByNameAndType, findVariableOrDefaultToTempateValue } from "../../utils/variable-extractor";
import ModuleSelector from "../../components/modules/_module-selector";
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";

export default function FeedingEventCard() {
    const navigate = useNavigate();
    const location = useLocation();
    const { cardId } = useParams();
    const [params, setSearchParams] = useSearchParams();
    const [chapter, setChapter] = useState(null);
    const [chapters, setChapters] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [cardIndex, setCardIndex] = useState(null);
    const [currentCard, setCurrentCard] = useState(null);
    const [frontTemplate, setFrontTemplate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectionOpen, setSelectionOpen] = useState(false);
    const [isPhysical, setIsPhysical] = useState(false);
    const [selectionPage, setSelectionPage] = useState(null);
    const [selectionPageFrontTemplate, setSelectionPageFrontTemplate] = useState(null);
    const [dialoguePageNumber, setDialoguePageNumber] = useState(null);
    const [title, setTitle] = useState(null);
    const [city, setCity] = useState(null);
    const [level, setLevel] = useState(null);

    const direction = params.get('direction');
    const pathname = location.pathname;

    let cardsOrder = [];
    Sections.forEach(s => {
        cardsOrder = [...cardsOrder, ...s.districts];
    });

    const findCard = (id) => {
        if (!chapter) return;
        return chapter.cards?.find(_ => _._id === id);
    }

    const getCardIndex = (id) => {
        if (!chapter) return;
        return orderCards([...chapter.cards], chapter.cardsOrder.filter(_ => _.type === "card")).findIndex(_ => _._id === id);
    }

    const handlers = useSwipeable({
        onSwipedRight: (eventData) => {
            handleGoBack()
        },
        onSwipedLeft: (data) => {
            handleGoNext()
        }
    });

    useEffect(() => {
        if (!templateData || !cardId || !chapter) return;


        let card = findCard(cardId);
        if (!card) return;

        setCardIndex(getCardIndex(cardId));
        setCurrentCard(card);
        setFrontTemplate(templateData.currentVariants.find(_ => _._id === card.frontVariant));

        var currentCardIndex = chapter.cards.findIndex(_ => _._id === cardId);
        const sPage = chapter.cards[currentCardIndex + 1];
        // find next page
        if (sPage) {
            setSelectionPage(sPage);
            setSelectionPageFrontTemplate(templateData.currentVariants.find(_ => _._id === sPage?.frontVariant));
        }

        // setHiddenCards(hiddenCardsData.find(_ => _.id === chapterId));

        setLoading(false);
    }, [cardId, chapter, templateData, chapters]);

    useEffect(() => {
        ChapterManager.loadChaptersAsync((data) => {
            setChapters(data);
        });

        ChapterManager.loadChapterDataAsync("feeding-event-cards", (data) => {
            setChapter(data);
        });

        ChapterManager.loadTemplateDataAsync((data) => {
            setTemplateData(data);
        })
    }, []);

    const handleMenuClick = () => {
        if (menuOpen === null) {
            setMenuOpen(true);
            return;
        }
        setMenuOpen(!menuOpen);
    }

    useEffect(() => {
        if (frontTemplate) {
            let cityUuid = frontTemplate?.render[0].children.find(_ => _.name === "Right Side Section")?.children[1].children[0].uuid;
            let levelUuid = frontTemplate?.render[0].children.find(_ => _.name === "Right Side Section")?.children[1].children[1].uuid;

            setCity(findVariableOrDefaultToTempateValue(cityUuid, frontTemplate, chapter.cards, currentCard, currentCard.variablesValues, templateData, null, null, true));
            setLevel(findVariableOrDefaultToTempateValue(levelUuid, frontTemplate, chapter.cards, currentCard, currentCard.variablesValues, templateData, null, null, true));
            setDialoguePageNumber(findVariableOrDefaultToTempateValue(findRenderObjectByNameAndType("Dialogue Page Number", "text", frontTemplate?.render)?.uuid, frontTemplate, chapter.cards, currentCard, currentCard.variablesValues, templateData));
            setTitle(findVariableOrDefaultToTempateValue(findRenderObjectByNameAndType("Title", "text", frontTemplate?.render)?.uuid, frontTemplate, chapter.cards, currentCard, currentCard.variablesValues, templateData));
        }

    }, [frontTemplate, currentCard]);

    const handleGoBack = () => {
        let index = cardsOrder.findIndex(_ => _.id === currentCard._id)

        if (index > 0) {
            navigate(`/feeding-event/${cardsOrder[index - 1].id}?direction=1`);
        }
    };

    const handleGoNext = () => {
        let index = cardsOrder.findIndex(_ => _.id === currentCard._id)

        if (index + 1 < cardsOrder.length) {
            navigate("/feeding-event/" + cardsOrder[index + 1].id);
        }
    };

    const navigationStyle = {
        width: isMobile ? "30px" : "20px",
        backgroundColor: isMobile ? "transparent" : 'white',
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    }

    const feedingEventsTemplatePages = ["Feeding Event Hochelaga", "Feeding Event Montréal Nord", "Feeding Event Plateau", "Feeding Event Downtown", "Feeding Event Westmount",
        "Feeding Event Old montreal"];

    return (<>
        <div className="flex relative w-full h-full">
            {!loading && (<div className={isMobile ? 'absolute left-0' : 'show'}
                style={navigationStyle} onClick={() => { handleGoBack() }}>
                {!isMobile && (<FontAwesomeIcon icon={faCaretLeft} />)}
            </div>
            )}
            {!menuOpen && !selectionOpen &&
                (<div className='open-close-container'>
                    <a className='open-close-button' href="#1" onClick={() => handleMenuClick()}>
                        <BarsIcon width={50} height={50} fill={"black"} />
                    </a>
                </div>)}


            {loading || !currentCard ? (<Loading />) : (
                <div className="relative w-full h-full overflow-x-hidden">
                    <AnimatePresence>
                        <motion.div className="w-full h-full" {...handlers} key={pathname} name={pathname}
                            initial={{ x: direction ? "-100%" : '100%', position: "fixed" }}
                            animate={{ x: '0', position: 'relative' }}
                            exit={{ opacity: 0, position: "fixed" }}
                            transition={{
                                x: { type: "spring", bounce: 0 },
                                opacity: { duration: 0.2 }
                            }}
                        >
                            {frontTemplate?.name === "Feeding Event Separator" && (<FeedingEventSeparator template={frontTemplate} currentCard={currentCard} templateData={templateData} cards={chapter.cards} />)}
                            {feedingEventsTemplatePages.indexOf(frontTemplate?.name) > -1 && (<FeedingEventPage template={frontTemplate} currentCard={currentCard} templateData={templateData} cards={chapter.cards} selectionOpen={selectionOpen} setSelectionOpen={setSelectionOpen} setIsPhysical={setIsPhysical} />)}
                        </motion.div>
                    </AnimatePresence>
                </div>
            )}

            {!loading && (<div className={isMobile ? 'absolute right-0' : 'show'}
                style={navigationStyle} onClick={() => { handleGoNext() }}>
                {!isMobile && (<FontAwesomeIcon icon={faCaretRight} />)}
            </div>)}

            {selectionPage && selectionPageFrontTemplate && (<SelectionResultPage selectionOpen={selectionOpen}
                setSelectionOpen={setSelectionOpen}
                physical={isPhysical}
                currentCard={selectionPage}
                template={selectionPageFrontTemplate}
                cards={chapter.cards}
                dialoguePageNumber={dialoguePageNumber}
                title={title}
                city={city}
                level={level}
                templateData={templateData} >
                {findModuleVariables(isPhysical ? "f49ae83c-5605-4a29-92c1-ed65a82a8b59" : "3ae706f7-ed08-41b3-a1c2-26e3e4c55953", currentCard)?.filter(_ => [
                    "656cd95489231f88c3f6e679"].indexOf(_.ctaId) > -1)?.map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={chapter.cards}
                                templateData={templateData}
                            />
                        )
                    })}

                <div className="force-font-color">
                    {findModuleVariables(isPhysical ? "f49ae83c-5605-4a29-92c1-ed65a82a8b59" : "3ae706f7-ed08-41b3-a1c2-26e3e4c55953", currentCard)?.filter(_ => [
                        "656cd95489231f88c3f6e679"].indexOf(_.ctaId) === -1)?.map((variable, index) => {
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={currentCard}
                                    cards={chapter.cards}
                                    templateData={templateData}
                                />
                            )
                        })}
                </div>
            </SelectionResultPage>)}
        </div>


        <MainMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} chapters={chapters}></MainMenu>
    </>);
}