import { CONTENT_WIDTH } from '../../utils/constants';
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from "./_module-selector";
import VariableValueExtractor from "../utils/VariableValueExtractor";


const IfYouHaveDisciplineLevelXSandbox = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables.variablesValues) {
        variables = variables.variablesValues;
    }

    const mainContainer = template.render[0];
    const secondContainer = template.render[0].children[0];
    const childContainer = secondContainer.children[1];
    const childText1 = childContainer.children[0];
    const childText2 = childContainer.children[1];
    
    
    var textBody = secondContainer.children[0];
    var textText= secondContainer.children[2];

    
    const secondText = template.render[0].children[1].type === 'container' ? template.render[0].children[1]?.children[0] : null;


    const masterContainerStyle = {
        ...getContainerStyle(mainContainer.uuid, template, cards, card, variables, templateData, null),
        padding:"7px"
    }

    const childContainerStyle = {
        ...getContainerStyle(childContainer.uuid, template, cards, card, variables, templateData, null),
        padding:'1px',
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
            <div className="title flex items-center flex-wrap">
                <div className="flex items-center flex-nowrap text-nowrap">
                    <VariableValueExtractor variableId={textBody.uuid}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                    <div className="child-container flex mx-1" style={childContainerStyle}>
                        <div className='p-0.5 flex'>
                        <VariableValueExtractor variableId={childText1.uuid}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>

                        <VariableValueExtractor variableId={childText2.uuid}
                            template={template}
                            cards={cards}
                            card={card}
                            variables={variables}
                            templateData={templateData}
                            renderId={null}
                            isLink={null} 
                            ctaId={ctaId}
                            parentId={parentId}/>
                        </div>
                    </div>
                </div>
                
                <div className="flex items-center flex-nowrap text-nowrap">
                    <VariableValueExtractor variableId={textText.uuid}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            <VariableValueExtractor variableId={secondText?.uuid}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>

            <div>
                {findModuleVariables("6011bfcf-c0fb-4e8e-8293-926b955cbc12", variables).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={card}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            fullWidth={true}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default IfYouHaveDisciplineLevelXSandbox;