import ModuleSelector from "../../../components/modules/_module-selector";
import { BLOB_URL, V_NUMBER } from "../../../utils/constants";
import { findModuleVariables, findVariableOrDefaultToTempateValue } from "../../../utils/variable-extractor";
import { ReactComponent as HandIcon } from "../../../components/icons/DefaultIconSvgs/Swipe.svg";
import { Link } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";

export default function FeedingEventSeparator({
    currentCard,
    template,
    templateData,
    cards
}) {
    const {t} = useTranslation();


    let backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("143332bb-a64d-40ad-9e27-0712e4306f80", template, cards, currentCard, currentCard.variablesValues, templateData)?.value;
    let districtImage = BLOB_URL + findVariableOrDefaultToTempateValue("caf2fc7a-4fbe-4a74-8a73-7ca5e308d77a", template, cards, currentCard, currentCard.variablesValues, templateData)?.value;
    

    return (
        <div name={template.name}
            className="w-full h-full flex-col bg-center bg-cover flex justify-center items-center pb-10 pt-10"
            style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
            <div className="flex w-full h-full items-center flex-col">
                <div style={{ height: "70px", width: "10px" }}></div>
                <img className="mb-10" src={districtImage + "?v=" + V_NUMBER} width={200} alt="district"></img>
                <img width={400} height={20} src={BLOB_URL + "upload\\images\\64ed011e17b7622c4d323360\\Booklets\\icons/Ank+Line.webp"} alt="line"></img>

                {findVariableOrDefaultToTempateValue("4ceababc-d7a8-4af7-b49b-3e0fc9380c73", template, cards, currentCard, currentCard.variablesValues, templateData, "")}

                <div className="flex items-center justify-center flex-col mt-2 text-center">
                    {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                templateData={templateData}
                            />
                        )
                    })}
                </div>
                <div className="mt-10 flex flex-col items-center text-center w-3/4">
                    <HandIcon width={50} height={50} />
                    <p
                        className="mt-2"
                        style={{
                            fontFamily: "CrimsonPro",
                            color: "#949494",
                            fontWeight: "bold",
                            fontStyle: "italic",
                        }}
                    >
                        {t("swipe-left-or-right")}
                    </p>
                </div>

                <div className="my-10">
                    <Link className="back-to-district-button" to={"/feeding-event"}>
                        {t("back-to-district")}
                    </Link>
                </div>
            </div>
        </div>)
}