
import { ReactComponent as XIcon } from '../../../components/icons/DefaultIconSvgs/X.svg'
import ModuleSelector from '../../../components/modules/_module-selector';
import { BLOB_URL, CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../../utils/constants';
import { FONT_SIZE_MP } from '../../../utils/libs';
import { findModuleVariables, findRenderObjectByNameAndType, findVariableOrDefaultToTempateValue } from '../../../utils/variable-extractor';

export default function SelectionResultPage({
    physical,
    currentCard,
    selectionOpen,
    setSelectionOpen,
    template,
    templateData,
    cards,
    title,
    dialoguePageNumber,
    city,
    level,
    children
}) {
    const handleMenuClick = () => {
        if (selectionOpen === null) {
            setSelectionOpen(true);
            return;
        }
        setSelectionOpen(!selectionOpen);
    }

    return (
        <>
            {selectionOpen &&
                (<div className="absolute w-full h-full overflow-y-auto pb-10" style={{ backgroundColor: "white" }}>
                    <div className='open-close-container'>
                        <a className='open-close-button' href="#click" onClick={() => handleMenuClick()}>
                            <XIcon height={50} width={50} fill='black' />
                        </a>
                    </div>
                    <div className='top-section flex flex-col grow'>
                        <div className='header flex'>
                            <div className='flex flex-col justify-between items-center w-full'>
                                <div className='flex flex-col my-5' style={{ width: CONTENT_WIDTH }}>
                                    <div className='flex' style={{ color: DEFAULT_ORANGE_CODE, fontSize: "15px", fontFamily: "CrimsonPro-Bold" }}>
                                       {city}
                                        <span className='mx-2'>-</span>
                                        {level}
                                    </div>
                                    <div className='flex'>
                                        {dialoguePageNumber}
                                        <div className='ml-1' style={{ color: 'black', fontSize: '18px', fontFamily: 'Oswald-SemiBold', fontWeight: '400', textTransform: 'uppercase' }}>
                                            {title}
                                        </div>
                                    </div>
                                </div>
                                <div>

                                </div>
                                <div className='full-to-fade-gradient' style={{ height: "2px", width: "100%" }}></div>
                            </div>
                        </div>
                        <div className='flex items-center justify-center flex-col mt-2'>
                            <div style={{width:CONTENT_WIDTH, color:"black"}}>
                                    {children}
                            </div>
                        </div>

                        <div className='flex items-center justify-center flex-col mt-10'>
                            {findModuleVariables("42dd4da9-a477-46b7-bade-6426250db73d", currentCard)?.map((variable, index) => {
                                return (
                                    <ModuleSelector
                                        key={index}
                                        variable={variable}
                                        card={currentCard}
                                        cards={cards}
                                        templateData={templateData}
                                        isPhysical={physical}
                                    />
                                )
                            })}
                        </div>

                        <div className='flex items-center flex-col mt-10'>
                            <img src={BLOB_URL +"upload/images/64ed011e17b7622c4d323360/Booklets/icons/Ank+Line.webp"} style={{ maxWidth: "300px" }} alt="ank-line" />
                        </div>

                    </div>
                    <div className='flex items-center justify-center flex-col mt-2'>
                            {findModuleVariables("0cf0533e-0a9f-419c-81fc-6d18c5777072", currentCard)?.map((variable, index) => {
                                return (
                                    <ModuleSelector
                                        key={index}
                                        variable={variable}
                                        card={currentCard}
                                        cards={cards}
                                        deckId={currentCard.deck}
                                        templateData={templateData}
                                    />
                                )
                            })}
                        </div>
                </div>)}

        </>)
}