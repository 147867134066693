import { BLOB_URL, V_NUMBER } from '../../utils/constants';
import { findNestedVariable } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const SetupNpcToken = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    // XXX: "73a1b176-a04b-4188-8a2f-31be7bcfed34"

    if(variables && variables.variablesValues){
        variables = variables.variablesValues;
    }    

    var image = findNestedVariable("7e1ea13b-d5b0-4bc3-a529-7fc71145dd43", variables);

    var token = templateData.tokens.find(_=>_._id === image.value);
    var url = token.image;


    return (
        <div name={`${template._id} - ${template.name}`} className="flex flex-wrap justify-center px-2" style={{minWidth:"100px"}}>
            <div className='flex flex-col text-center items-center' style={{width:"145px", fontFamily:"CrimsonPro-Bold"}}>
                <img src={BLOB_URL + url + "?v=" + V_NUMBER} width={100} alt="token"/>
                <VariableValueExtractor variableId={"eee26fa8-fb7b-4a0a-baf0-7faa2ce4fbb4"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>

                <VariableValueExtractor variableId={"2641bff7-9cc8-429e-9136-45d5f22f1353"}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink={false} 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
        </div>
    )
}

export default SetupNpcToken;