import { BLOB_URL, CONTENT_WIDTH, V_NUMBER } from '../../utils/constants';
import { findVariableOrDefaultToTempateValue, getContainerStyle } from '../../utils/variable-extractor';
import VariableValueExtractor from "../utils/VariableValueExtractor";

const IfAnotherCharacterEndsTheirTurnOnAnHexAdjacentToYou = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }
    

    let containerRender = template.render[0];
    let textRender = containerRender.children[0];

    const masterContainerStyle = {
        ...getContainerStyle(containerRender.uuid, template, cards, card, variables, templateData, null),
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"7px",
        paddingRight:"7px"
    }

    let imageRender = containerRender.children[1].children[0];
   

    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex justify-between" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null  }}>
            <div>
                <VariableValueExtractor variableId={textRender.uuid}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={null}
                    isLink 
                    ctaId={ctaId}
                    parentId={parentId}/>
            </div>
            <div>
                <img alt="icon" width='40px' src={BLOB_URL + findVariableOrDefaultToTempateValue(imageRender.uuid, template, cards, card, variables, templateData, null).image + "?v=" + V_NUMBER}></img>
            </div>
          
        </div>
    )
}

export default IfAnotherCharacterEndsTheirTurnOnAnHexAdjacentToYou;