import { getContainerStyle, findModuleVariables, findNestedVariable, getRenderColor } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import { CONTENT_WIDTH } from '../../utils/constants';
import ClanIcons from '../icons/ClanIcons';


const TemplateTextGeneral2 = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    
    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3 flex flex-col text-center relative" style={{  width: !fullWidth ? CONTENT_WIDTH : null }}>
 
            <VariableValueExtractor variableId={"bd98ba60-a460-4e69-8d4a-b7285f1c3780"}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/> 

            <div className='flex justify-start absolute right-0'>
                <ClanIcons name={findNestedVariable("0fb30447-3468-48c1-aaaf-3c60050b97df", variables)?.value}
                color={getRenderColor("5d8b7311-53f0-46b1-b68e-d2788460f34b", template.render, templateData)} />
            </div>  
        </div>
    )
}

export default TemplateTextGeneral2;