import './_templates.scss'
import { findVariableOrDefaultToTempateValue, findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from '../modules/_module-selector';
import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from '../../utils/constants';
import { FONT_SIZE_MP } from '../../utils/libs';
import { Link } from 'react-router-dom';
import HexGrid from '../tools/HexGrid';
import { BLOB_URL } from "../../utils/constants";
import VariableValueExtractor from "../utils/VariableValueExtractor";

const ChapterIntro1NpcColumnPatrol = ({
    currentCard,
    template,
    cards,
    deckId,
    templateData,
    eventPage,
    ctaId,
}) => {

    const parentId = currentCard._id;

    let backgroundImage = BLOB_URL + findVariableOrDefaultToTempateValue("04d5acba-ca3f-4719-8def-9ff7053a6eba",
      template,
      cards,
      currentCard,
      currentCard.variablesValues,
      templateData
    )?.image;

    const masterContainerStyle = {
        ...getContainerStyle("309721ec-23a3-45f3-bf7c-5eb07dd37ca8", template, cards, currentCard, currentCard.variablesValues, templateData, null)
    }

    const patrolContainerStyle = {
        ...getContainerStyle("2e56f91c-8d0c-4bac-9de1-c6219250ef85", template, cards, currentCard, currentCard.variablesValues, templateData, null),
        opacity:0.7
    }

  
    return (
        <div className="w-full h-full flex flex-col overflow-y-auto" style={masterContainerStyle}>
            <div className='w-full text-center' style={{ marginTop: "50px" }}>
                <h5 style={{ fontFamily: "CrimsonPro", color: DEFAULT_ORANGE_CODE, fontWeight: "bold", fontSize: `${8 * FONT_SIZE_MP}px` }}>
                    <VariableValueExtractor variableId={"22c19678-71f0-48e7-ab79-f63781c10870"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={null}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </h5>
                <h1 style={{ fontFamily: "Oswald-Bold", color: "Black", fontWeight: "bold", fontSize: `${20 * FONT_SIZE_MP}px` }}>
                    INTRODUCTION
                </h1>
            </div>

            <div className='force-font-size flex flex-col items-center mt-3'
                style={{ fontFamily: "CrimsoPro", color: "black" }}>
                {findModuleVariables("4ca9105a-fe49-466d-8748-e0885a8b75eb", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}

                {findModuleVariables("097cbf93-f94a-4978-9da6-902dfed35639", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
            </div>

            <div className='flex justify-center my-5'>
                <div style={{
                    width: CONTENT_WIDTH,
                    ...getContainerStyle("3cf18226-4350-45a2-af99-ada617e7cb38", template, cards, currentCard, currentCard.variablesValues, templateData),
                    padding: '15px'
                }}>
                    <h3 style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "CrimsonPro" }}>
                        OBJECTIVE
                    </h3>

                    <div style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                        <VariableValueExtractor variableId={"3b0596d3-eb6f-47fe-a021-f1b9d16402e7"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>

                    <div className='line my-4'></div>

                    <h3 style={{ color: DEFAULT_ORANGE_CODE, fontFamily: "CrimsonPro", }}>
                        ACTION
                    </h3>
                    <div style={{textTransform: "uppercase", fontWeight: "400", fontFamily: "Oswald-Regular", color: "rgb(241, 228, 215)", fontSize: "25px"}}>
                        <Link to={`/${deckId}/${eventPage}`}>
                            <VariableValueExtractor variableId={"0fcc37f2-6788-41ed-a982-aa3903e08f35"}
                                template={template}
                                cards={cards}
                                card={currentCard}
                                variables={currentCard.variablesValues}
                                templateData={templateData}
                                renderId={"b1c09af8-231e-4d0f-b7ec-fbe1fa7ee9b3"}
                                isLink 
                                ctaId={ctaId}
                                parentId={parentId}/>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center my-5'>
                <div style={{ width: '175px' }}>
                    <div className='line my-2' style={{ height: '2px' }}></div>
                    <h3 className='text-center' style={{ fontFamily: "Oswald-Regular", fontSize: '25px' }}>INITIAL TILE <span className='orange-text ftOswaldSb'>SETUP</span></h3>
                    <div className='line my-2' style={{ height: '2px' }}></div>
                </div>
            </div>

            <div className='justify-center mb-5 items-center' style={patrolContainerStyle}>
                {currentCard._id === "6554f8da451d7e555cc1abec" ? 
                (<>

                    {findModuleVariables("f17b4bff-61ea-41e2-abd8-455c6d86ecf8", currentCard).map((variable, index) => {
                        if(variable.ctaId === '6554faed451d7e555cc1f3a6'){
                            return (
                                <div className='grow mb-3' key={index}>
                                    <ModuleSelector
                                        key={index}
                                        variable={variable}
                                        card={currentCard}
                                        cards={cards}
                                        deckId={deckId}
                                        fullWidth={true}
                                        templateData={templateData}
                                        noStyle={true}
                                    />
                                </div>
                            )
                        }

                        return null;
                    })}

                    <div className='flex justify-center mb-5 items-center flex-wrap'>
                    {findModuleVariables("f17b4bff-61ea-41e2-abd8-455c6d86ecf8", currentCard).map((variable, index) => {

                        if(variable.ctaId !== '6554faed451d7e555cc1f3a6'){
                            return (
                                <ModuleSelector
                                    key={index}
                                    variable={variable}
                                    card={currentCard}
                                    cards={cards}
                                    deckId={deckId}
                                    templateData={templateData}
                                    noStyle={true}
                                />
                            )
                        }

                        return null;
                    })}
                    </div>
                    
                </>) : 
                
                (<>
                    {findModuleVariables("f17b4bff-61ea-41e2-abd8-455c6d86ecf8", currentCard).map((variable, index) => {
                        return (
                            <ModuleSelector
                                key={index}
                                variable={variable}
                                card={currentCard}
                                cards={cards}
                                deckId={deckId}
                                templateData={templateData}
                                noStyle={true}
                            />
                        )
                    })}
                </>)}
            </div>

            <div className='flex justify-center mb-5'>
                <HexGrid
                    variables={currentCard.variablesValues}
                    template={template}
                    cards={cards}
                    card={currentCard}
                    templateData={templateData}
                    variable="633b2f6d-3791-47f3-80d8-76f91974c795"
                />
            </div>

            <div className='flex flex-wrap justify-center mb-5'>
                {findModuleVariables("76bf0b33-0d80-4042-bfa1-415265b8b776", currentCard).map((variable, index) => {
                    return (
                        <ModuleSelector
                            key={index}
                            variable={variable}
                            card={currentCard}
                            cards={cards}
                            deckId={deckId}
                            templateData={templateData}
                            noStyle={true}
                        />
                    )
                })}
            </div>

            <div className='flex justify-center'>
                <div className='flex flex-col justify-center mb-5 p-2 force-font-size' style={{backgroundImage: `url(${backgroundImage})`, width: CONTENT_WIDTH }}>
                    <div className="mb-2">
                        <VariableValueExtractor variableId={"28626105-71f1-49a7-b5b8-df911f1533c0"}
                            template={template}
                            cards={cards}
                            card={currentCard}
                            variables={currentCard.variablesValues}
                            templateData={templateData}
                            renderId={null}
                            isLink 
                            ctaId={ctaId}
                            parentId={parentId}/>
                    </div>
                    <VariableValueExtractor variableId={"3eb8e9c7-f1c0-46ae-b8a4-399ea8bc5aa7"}
                        template={template}
                        cards={cards}
                        card={currentCard}
                        variables={currentCard.variablesValues}
                        templateData={templateData}
                        renderId={"2a0621c4-ae1d-494b-9d89-0da50f5aeeac"}
                        isLink 
                        ctaId={ctaId}
                        parentId={parentId}/>
                </div>
            </div>

            
        </div>
    )
}

export default ChapterIntro1NpcColumnPatrol;