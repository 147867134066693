import { CONTENT_WIDTH } from '../../utils/constants';
import VariableValueExtractor from "../utils/VariableValueExtractor";
import { findModuleVariables, getContainerStyle } from '../../utils/variable-extractor';
import ModuleSelector from './_module-selector';

const InterludeSandboxSection = ({
    variables,
    template,
    cards,
    card,
    deckId,
    fullWidth,
    templateData,
    ctaId,
    parentId,
    removeLink
}) => {

    if (variables && variables.variablesValues) {
        variables = variables.variablesValues;
    }

    let groupContainer = template.render[0];
    let topContainer = groupContainer.children[0];
    var bodyText = topContainer.children[0];
    let bottomContainer = groupContainer.children[1];
    var readText = bottomContainer.children[0];

    const masterContainerStyle = {
        ...getContainerStyle(groupContainer.uuid, template, cards, card, variables, templateData, null),
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "7px",
        paddingRight: "7px"
    }


    return (
        <div name={`${template._id} - ${template.name}`} className="mt-3" style={{ ...masterContainerStyle, width: !fullWidth ? CONTENT_WIDTH : null }}>
                <VariableValueExtractor variableId={bodyText.variable}
                    template={template}
                    cards={cards}
                    card={card}
                    variables={variables}
                    templateData={templateData}
                    renderId={bodyText.uuid}
                    isLink={!removeLink}
                    ctaId={ctaId}
                    parentId={parentId} />

                    <VariableValueExtractor variableId={readText.uuid}
                        template={template}
                        cards={cards}
                        card={card}
                        variables={variables}
                        templateData={templateData}
                        renderId={readText.uuid}
                        isLink={false}
                        ctaId={ctaId}
                        parentId={parentId}
                    />
            </div>
    )
}

export default InterludeSandboxSection;